.SupportTicketMessage {
	padding: 10px;
	margin-left: -10px;
}

.SupportTicketMessage.private {
	background: rgba( 250, 245, 227, 0.596 );
}

.SupportTicketMessage__content {
	flex: 1;
	line-height: 1.6;
	margin-left: 55px;
	margin-top: -10px;
}

.SupportTicketMessage__content pre {
	font-size: inherit;
}

.SupportTicketMessage__content pre code {
	background-color: transparent;
	padding: 0;
}

.SupportTicketMessage__content blockquote {
	border-left: 4px #dfe2e5 solid;
	color: #6a737d;
	margin-left: 0;
	padding-left: 14px;
}

.SupportTicketMessage--source-email .SupportTicketMessage__content {
	white-space: pre-wrap;
}

@media(max-width: 500px) {
	.SupportTicketMessage__content {
		margin-left: 0;
	}
}

.SupportTicketMessage__attachments {
	background: #f5f6f9;
	padding: 1em;
	margin-left: 55px;
}

.SupportTicketMessage__attachments-header {
	font-size: 1.1em;
	margin: 0 0 0.5em;
}

.SupportTicketMessage__attachments li {
	padding: 5px;
	margin-left: 1em;
}
