.UnifiedDeployListItem {
	padding: 26px 13px;
	margin-bottom: 5px;
	border: 2px solid transparent;
	border-radius: 4px;
}

.UnifiedDeployListItem--current {
	background: #f0f7f6;
}

.UnifiedDeployListItem--expanded {
	border-color: #f0f7f6;
}

.UnifiedDeployListItem--pinned {
	border-bottom: 1px solid #4667de;
	margin-bottom: 10px;
}

.UnifiedDeployListItem--status-abandoned {
	filter: grayscale(100%);
	opacity: 0.5;
}

.UnifiedDeployListItem__summary {
	display: flex;
	flex-shrink: 0;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.UnifiedDeployListItem__summary .ActivityListItem {
	flex: 1;
	margin-right: 20px;
}

.UnifiedDeployListItem__summary .OutlineButton {
	margin-left: 20px;
}

.UnifiedDeployListItem__expando {
	font-size: 20px;
	color: #666;
	margin-right: 15px;
	cursor: pointer;
}

.UnifiedDeployListItem .TaskLog {
	margin: 20px 0;
}
