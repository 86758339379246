.OutlineButton {
	text-align: center;
	min-width: 110px;
	line-height: 40px;
	border: 1px solid;
	border-radius: 20px;
	font-size: 16px;
	padding: 0 25px;
	font-weight: 500;
	cursor: pointer;
}

.OutlineButton[disabled] {
	cursor: not-allowed;
}
