.ApplicationLogs-BasicTable__table {
	width: 100%;
}

.ApplicationLogs-BasicTable__date {
	width: 13em;
	vertical-align: top;
}

.ApplicationLogs-BasicTable__date .ApplicationLogs-Timestamp {
	font-family: var( --font-monospace );
}

tbody .ApplicationLogs-BasicTable__message {
	font-family: var( --font-monospace );
	font-size: 12px;
	white-space: pre-wrap;
}
