.ApplicationLogs__picker {
	margin: 1em 0;
	width: 100%;
	display: grid;
	grid-template-columns: repeat( 3, 1fr );
	column-gap: 1em;
	row-gap: 1em;
}

.ApplicationLogs__picker > button {
	box-shadow: 0 2px 15px -5px rgba( 91, 105, 131, 0.30 );
	border-radius: 1px;
	padding: 1em;
	position: relative;
	cursor: pointer;
	text-align: left;

	display: grid;
	grid-template-rows: auto 60px;
}

.ApplicationLogs__picker-label {
	font-weight: 500;
	display: flex;
	align-items: center;
	color: #4667DE;
	transition: color 120ms linear;
}

.ApplicationLogs__picker-label svg {
	transform: translate(0, 0);
	transition: transform 100ms;
}

.ApplicationLogs__picker-link:hover .ApplicationLogs__picker-label {
	color: rgb(19, 41, 121);
}

.ApplicationLogs__picker-link:hover .ApplicationLogs__picker-label svg {
	transform: translate(2px, 0);
}

.ApplicationLogs__picker .recharts-wrapper {
	cursor: pointer !important;
}
.ApplicationLogs__picker .LiveLineChart {
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
}

.ApplicationLogs__header {
	margin: 1em 0 2em;
	display: grid;
	grid-template-columns: auto max-content;
	align-items: center;
	border-bottom: 1px solid #eee;
}
