.SupportTicketUpdateForm {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.SupportTicketUpdateForm > label {
	display: flex;
	align-items: center;
	margin-right: 10px;
	justify-content: space-between;
}

.SupportTicketUpdateForm > label > div {
	min-width: 180px;
	margin-left: 10px;
}

@media(max-width: 500px) {
	.SupportTicketUpdateForm {
		flex-direction: column;
	}

	.SupportTicketUpdateForm > label {
		margin-right: 0;
		margin-bottom: 20px;
	}

	.SupportTicketUpdateForm .SolidButton {
		align-self: flex-end;
	}
}
