h1.BigTitle, .BigTitle {
	font-size: 26px;
	line-height: 30px;
	margin: 0;
	color: #152A4E;
	font-weight: 500;
	display: inline-block;
}
.BigTitle.isLoading {
	width: 50%;
	height: 36px;
}
