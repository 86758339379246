.ApplicationData__info {
	background: #f5f6f9;
	line-height: 1.4;
	margin: 1em 0;
	padding: 13px 13px;
	border-radius: 4px;
}

.ApplicationData__info p {
	margin: 0 0 1em;
}

.ApplicationData__header {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
}

.ApplicationData__snapshot {
	display: flex;
	padding: 13px 13px;
	margin-top: 5px;
	margin-bottom: 5px;
	border-radius: 4px;
	color: #5B6983;
	border-bottom: 1px dashed #d0d8e5;
}

.ApplicationData__snapshot--pit {
	background: #f0f7f6;
	border-bottom: 1px solid #4667de;
}

.ApplicationData__snapshot-loading {
	margin-top: 5px;
	margin-bottom: 5px;
	border-bottom: 1px dashed #d0d8e5;
}

.ApplicationData__snapshot p {
	margin: 5px 0 0;
}

.ApplicationData__snapshot-description {
	font-size: 16px;
	line-height: 1.4;
}

.ApplicationData__snapshot-id {
    color: #152A4E;
}

.ApplicationData__snapshot-id code {
	display: inline-block;
	margin-left: -0.4em;
}
