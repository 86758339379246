.ApplicationShell-SessionItem {
	display: flex;
	flex-shrink: 0;
	flex-direction: column;
	justify-content: space-between;
	padding: 26px 13px;
	margin-bottom: 5px;
	border: 2px solid transparent;
	border-radius: 4px;
}

.ApplicationShell-SessionItem--expanded {
	border-color: #f0f7f6;
}

.ApplicationShell-SessionItem--failed {
	background-color: rgba(226,24,44,0.10);
}

.ApplicationShell-SessionItem__header {
	display: flex;
	flex-shrink: 0;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.ApplicationShell-SessionItem__header .ActivityListItem {
	flex: 1;
	margin-right: 20px;
}

.ApplicationShell-SessionItem__expando {
	font-size: 20px;
	color: #666;
	margin-right: 15px;
	cursor: pointer;
}

.ApplicationShell-SessionItem strong {
	text-overflow: ellipsis;
	max-width: 500px;
	white-space: nowrap;
	overflow: hidden;
}

.ApplicationShell-SessionItem__log-error {
	margin: 1em 0;
	background: #222;
	color: #d3d3d3;
	border-radius: 3px;
}

.ApplicationShell-SessionItem__log-error p {
	margin: 1em;
}
