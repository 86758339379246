.SupportListItem, a.SupportListItem {
	display: flex;
	flex-direction: row;
	align-items: baseline;
	text-decoration: none;
	color: #111;
	padding: 1em 1em;
	margin: 0 -1em;
}

.SupportListItem:hover,
.SupportListItem:focus {
	background: rgba(70, 103, 222, 0.1);
}

.SupportListItem .avatar-wrapper {
	margin-right: 10px;
	position: relative;
}

.SupportListItem .avatar-wrapper .Avatar:nth-child(2) {
	position: absolute;
	bottom: 0;
	right: 0;
}

.SupportListItem__status {
	width: 80px;
	flex-shrink: 0;
	flex-grow: 0;
}

.SupportListItem__main {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	line-height: 1.6;
	overflow: hidden;
	margin-right: 1em;
}

.SupportListItem__details {
	font-size: 0.8em;
	color: #666;
}

.SupportListItem__details .Pill {
	margin-right: 0.5em;
}

.SupportListItem__status .Pill {
	display: inline-block;
	margin-top: 2px;
	padding: 1px 5px 1px 5px;
}

.SupportListItem__application {
	margin-right: 1.5em;
	width: 150px;
	flex-shrink: 0;
	flex-grow: 0;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-size: 0.8em;
}

.SupportListItem__assignee {
	margin-right: 1.5em;
	width: 150px;
	flex-shrink: 0;
	flex-grow: 0;
}

.SupportListItem .priority {
	margin-left: 10px;
}

.SupportListItem .date {
	width: 100px;
	flex-shrink: 0;
	flex-grow: 0;
	text-align: right;
}

@media(max-width: 500px) {
	.SupportListItem {
		display: grid;
		grid-template-columns: 3fr 1fr;
		row-gap: 0.5em;
	}

	.SupportListItem__main {
		grid-column: 1;
		grid-row: 1 / 4;
	}

	.SupportListItem__status {
		grid-column: 2;
		grid-row: 2;
		width: auto;
		justify-self: flex-end;
	}

	.SupportListItem .date {
		font-size: 12px;
		width: auto;
		padding: 0;
		color: #999;
		align-self: flex-end;
	}

	.SupportListItem__application {
		grid-column: 2;
		grid-row: 3;
		width: auto;
		margin: 0;
		text-align: right;
	}
}
