.ApplicationServersScaling {
	margin: 0 15px;
}

.ApplicationServersScaling p {
	display: flex;
	align-items: center;
	background: #f5f6f9;
	padding: 15px 10px;
}

.ApplicationServersScaling svg {
	margin-right: 1em;
}
