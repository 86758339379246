.XRayTrace {
	display: flex;
	flex-shrink: 0;
	flex-direction: column;
}

.XRayTrace .header-block {
	list-style: none;
	border: 1px solid #DDD;
	padding: 0;
	margin: 0;
}

.XRayTrace .header-block li {
	float: left;
	border-right: 1px solid #DDD;
	padding: 5px 20px 5px 5px;
	font-size: 12px;
	color: #666;
}

.XRayTrace .header-block li .value {
	color: black;
	font-size: 14px;
	display: block;
}

.XRayTrace .url {
	word-break: break-all;
	padding: 0.5rem;
}

.XRayTrace > .ErrorBlock {
	margin: 1em 0 0;
}

.XRayTrace pre {
	background-color: rgba(27,31,35,.05);
	border-radius: 3px;
	font-size: 85%;
	margin: 0;
	padding: .2em .4em;
}

.XRayTrace__tour-text:first-of-type {
	margin-top: 0;
}

.XRayTrace__tour-text:last-of-type {
	margin-bottom: 0;
}
