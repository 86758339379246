.Log {
	background-color: #222222;
	color: #d6d6d6;

	min-height: 100px;
	max-height: 400px;
	overflow: auto;
	padding: 1em 0 2em;
	margin: 1em 0;

	font: 400 12px/19px "Monaco", monospace;
	white-space: pre-wrap;

	/* Based on Verminal */
	--ansi-black: #222;
	--ansi-bright-black: #686868;
	--ansi-red: #FF3B30;
	--ansi-green: #4CD964;
	--ansi-yellow: #FFCC00;
	--ansi-blue: #0095FF;
	--ansi-magenta: #FF2D55;
	--ansi-cyan: #5AC8FA;
	--ansi-white: #fff;
}

.Log .Loader {
	stroke: #fff;
}

.Log__copy {
	position: sticky;
	top: 0;
	float: right;
	margin-right: 1em;
	white-space: normal;
}

.Log__line {
	padding-left: 77px;
}

.Log__line::before {
	display: inline-block;
	content: attr( data-number );
	width: 35px;
	margin: 0 15px 0 -62px;

	color: #666;
	border-right: 3px solid transparent;
	padding-right: 9px;

	text-align: right;
}

.Log__line:hover {
	background-color: #444444;
}

.Log .info {
	color: #FFFF91;
	font-weight: bold;
}

.Log__line.error::before {
	border-right: 3px red solid;
}

.Log .debug.stderr::before {
	border-color: #ED7B9D;
}

.Log .ansi-black-fg {
	color: var( --ansi-black );
}
.Log .ansi-black-bg {
	background-color: var( --ansi-black );
}
.Log .ansi-bright-black-fg {
	color: var( --ansi-bright-black );
}
.Log .ansi-bright-black-bg {
	background-color: var( --ansi-bright-black );
}
.Log .ansi-red-fg,
.Log .ansi-bright-red-fg {
	color: var( --ansi-red );
}
.Log .ansi-red-bg,
.Log .ansi-bright-red-bg {
	background-color: var( --ansi-red );
}
.Log .ansi-green-fg,
.Log .ansi-bright-green-fg {
	color: var( --ansi-green );
}
.Log .ansi-green-bg,
.Log .ansi-bright-green-bg {
	background-color: var( --ansi-green );
}
.Log .ansi-yellow-fg,
.Log .ansi-bright-yellow-fg {
	color: var( --ansi-yellow );
}
.Log .ansi-yellow-bg,
.Log .ansi-bright-yellow-bg {
	background-color: var( --ansi-yellow );
}
.Log .ansi-blue-fg,
.Log .ansi-bright-blue-fg {
	color: var( --ansi-blue );
}
.Log .ansi-blue-bg,
.Log .ansi-bright-blue-bg {
	background-color: var( --ansi-blue );
}
.Log .ansi-magenta-fg,
.Log .ansi-bright-magenta-fg {
	color: var( --ansi-magenta );
}
.Log .ansi-magenta-bg,
.Log .ansi-bright-magenta-bg {
	background-color: var( --ansi-magenta );
}
.Log .ansi-cyan-fg,
.Log .ansi-bright-cyan-fg {
	color: var( --ansi-cyan );
}
.Log .ansi-cyan-bg,
.Log .ansi-bright-cyan-bg {
	background-color: var( --ansi-cyan );
}
.Log .ansi-white-fg,
.Log .ansi-bright-white-fg {
	color: var( --ansi-white );
}
.Log .ansi-white-bg,
.Log .ansi-bright-white-bg {
	background-color: var( --ansi-white );
}
