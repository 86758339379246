.Sidebar-User {
	margin: 20px 10px 0;
	line-height: 18px;
	font-weight: normal;
	color: #fff;
	display: flex;
	align-items: center;
}

.Sidebar-User button {
	background: transparent;
	border: none;
	font: inherit;
	color: inherit;
	height: 35px;
	cursor: pointer;
}

a.Sidebar-User__profile {
	background: transparent;
	border: none;
	font: inherit;
	color: inherit;
	height: 35px;
	cursor: pointer;
	color: white;

	flex-grow: 1;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	padding: 10px 15px;
	border-radius: 4px;
}

a.Sidebar-User__profile:hover {
	color: inherit;
	background: rgba( 243, 245, 249, 0.05 );
}

.Sidebar-User .Avatar {
	margin-right: 13px;
}

.Sidebar-User__logged-in {
	font-size: 0.8em;
	display: block;
	opacity: 0.8;
}

.Sidebar-User__notifications {
	flex-shrink: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0.6;
	position: relative;
}

.Sidebar-User__notifications--has-notifs {
	opacity: 0.9;
}

.Sidebar-User__notification-count {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #E2182C;
	right: calc( 50% - 18px );
	top: calc( 50% - 18px );
	font-size: 0.7em;
	line-height: 1;
	width: 1.7em;
	height: 1.7em;
	text-align: center;
	border-radius: 50%;
}
