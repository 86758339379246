.SupportList .SupportListItemLoading {
	margin: 1em 0;
}

.SupportList > .TabBar {
	margin: 1em 0;
}

.SupportList form {
	margin: 1em 0;
	clear: both;
}

.SupportList__title {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex: 1;
	margin-bottom: 2em;
}

.SupportList__new {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 2em;
}

.SupportList__filter {
	display: flex;
	align-items: center;
	margin-bottom: 0.5em;
}

.SupportList__filter > * {
	flex-grow: 1;
}

.SupportList__filter-name {
	flex-grow: 0;
	width: 130px;
}

.SupportList__pin-note {
	font-size: 0.8em;
	margin: 2em 0;
}

.SupportList__pin-note button {
	text-decoration: underline;
	color: #4667DE;
	cursor: pointer;
}

@media (max-width: 500px) {
	.SupportList__title {
		display: block;
	}

	.SupportList__title .BigTitle {
		margin-top: 1em;
		margin-bottom: 1em;
	}

	.SupportList__new {
		display: block;
	}
}
