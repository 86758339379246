.WebServersListItem {
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	display: flex;
	flex-shrink: 0;
	height: 55px;
}
.WebServersListItem .server-name {
	display: flex;
	flex-shrink: 0;
	flex-direction: row;
	margin-right: 30px;
	width: 200px;
}
.WebServersListItem .server-name > div {
	display: flex;
	flex-shrink: 0;
	flex-direction: column;
}

.WebServersListItem .server-name .ServerStatusIndicator {
	margin-right: 15px;
}

.WebServersListItem .LiveLineChart {
	width: 200px;
	margin-right: 30px;
}
.WebServersListItem .ip-address {
	display: flex;
	flex-shrink: 0;
	flex-direction: column;
	flex-direction: row;
	margin-right: 30px;
}
.WebServersListItem .ip-address span {
	width: 120px;
	margin-right: 15px;
}
.WebServersListItem .ip-address svg {
	color: #DDD;
}
.WebServersListItem .ssh-connection {
	flex-direction: row;
	width: 160px;
}
.WebServersListItem .ssh-connection span {
	margin-right: 15px;
}
.WebServersListItem .ssh-connection svg {
	color: #DDD;
}

.WebServersListItem .web-server-copy-action {
	position:relative;
}

.WebServersListItem .web-server-copy-action button {
	cursor: pointer;
}

.WebServersListItem .copying-command {
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	background:#00C1A3;
	color:white;
	padding:3px 7px;
	font-size:13px;
	border-radius: 4px;
	opacity:0;
	z-index:300;
	visibility:hidden;
	transition: 0.3s;
}

.WebServersListItem .copying-command.show {
	opacity:1;
	visibility: visible;
}

@media (max-width: 500px) {
	.WebServersListItem .ip-address {
		display: none;
	}
	.WebServersListItem .ssh-connection {
		display: none;
	}
}
