.ApplicationDeploys {
	padding: 1em 0;
}

.ApplicationDeploys__header {
	display: grid;
	grid-template-columns: auto min-content;
	align-items: center;
	margin: 2em 0 0.5em;
}

.ApplicationDeploys__header h2 {
	font-size: 1.3em;
}

.ApplicationDeploys h3 {
	color: #152A4E;
	font-weight: 500;
	font-size: 18px;
	margin: 0;
}

.ApplicationDeploys .current-deploy {
	border-bottom: 1px dashed #DFE2E7;
	padding-bottom: 26px;
	margin-bottom: 45px;
}
