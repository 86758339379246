.ApplicationDeploys-Config {
	margin-left: 13px;
	margin-right: 13px;
	margin-bottom: 60px;

	background: #f5f6f9;
	line-height: 1.6;
	margin: 0 0 1em;
	border-radius: 4px;
	color: #808fa8;
}

.ApplicationDeploys-Config__summary,
.ApplicationDeploys-Config__footer {
	padding: 13px 13px;
}

.ApplicationDeploys .ApplicationDeploys-Config__summary h3 {
	font-size: 15px;
	font-weight: normal;
	text-transform: uppercase;
}

.ApplicationDeploys-Config__source {
	color: #333;
	margin: 0.5em 0;
}

.App .ApplicationDeploys-Config__source-logo {
	display: inline-block;
	height: 1.3em;
	margin-right: 0.5em;
}

.ApplicationDeploys-Config__source-branch-icon {
	margin: 0 0.5em 0 1.8em;
}

.ApplicationDeploys-config__source-branch {
	font-weight: bold;
}

.ApplicationDeploys-Config__footer {
	border-top: 1px solid #d0d8e5;
}

.ApplicationDeploys-Config__expando {
	cursor: pointer;
	display: flex;
	align-items: center;
}

.ApplicationDeploys-Config__advanced-actions {
	display: flex;
	flex-wrap: wrap;
	margin-top: 0.5em;
}

.ApplicationDeploys-Config__advanced-actions .ErrorBlock {
	order: -1;
	width: 100%;
	margin-bottom: 1em;
}

.ApplicationDeploys-Config__advanced-actions button {
	margin-right: 0.5em;
}

.ApplicationDeploys-Config__actions {
	display: flex;
	justify-content: flex-end;
	column-gap: 0.5em;
}
