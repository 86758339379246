.ApplicationLogs-PHP__table {
	width: 100%;
}

.ApplicationLogs-PHP__date {
	width: 13em;
}

.ApplicationLogs-PHP__date .ApplicationLogs-Timestamp {
	font-family: var( --font-monospace );
}

.ApplicationLogs-PHP__date-wrap {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

tbody .ApplicationLogs-PHP__message {
	font-family: var( --font-monospace );
	font-size: 12px;
	white-space: pre-wrap;
}

.ApplicationLogs-PHP__location {
	font-family: var( --font-monospace );
	width: 40ch;
}

tbody .ApplicationLogs-PHP__location {
	font-family: var( --font-monospace );
	word-wrap: nowrap;
}
