.Console {
	position: relative;
	display: flex;
	flex-direction: column;

	max-height: 70vh;
	border-radius: 3px;
	background-color: #222222;
	color: #d6d6d6;
	margin: 1em 0;
}

.Console--maximized, .Console--standalone {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: 0;
	border-radius: 0;
	z-index: 10000;
	max-height: none;
}

.Console__terminal {
	padding: 1em 15px 2em;
	flex-grow: 1;
	flex-shrink: 1;
	overflow: hidden;
}

.Console--popped .Console__terminal {
	visibility: hidden;
}

.Console__loader, .Console__popped-status {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgb(34, 34, 34, 0.5);
	border-radius: 3px;
}

.Console__loader .Loader {
	stroke: #fff;
}
