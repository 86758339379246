.XRayOverview-Tooltip {
	background: #fff;
	border: 1px solid #3FCF8E;
	padding: 0.5em;
	line-height: 1.2;
}

.XRayOverview-Tooltip p,
.XRayOverview-Tooltip ul {
	margin: 0;
}

.XRayOverview-Tooltip ul {
	list-style: none;
	padding: 0;
}
