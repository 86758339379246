.BackupApplicationModal {
	max-width: 600px;
	margin: 0 auto;
}

.BackupApplicationModal h2 {
	margin-top: 0;
	font-weight: normal;
}

.BackupApplicationModal label {
	font-size: 12px;
	font-weight: bold;
}

.BackupApplicationModal .submit {
	display: flex;
	margin: 0;
	justify-content: space-between;
}

.BackupApplicationModal .input {
	border-radius: 4px;
	border: 1px solid #ccc;
	font-size: 15px;
	line-height: 34px;
	padding: 0 10px;
	display: block;
}

.BackupApplicationModal input[type="checkbox"] {
	margin: 4px;
}

.BackupApplicationModal .path {
	margin: 8px 0 0 0;
	color: #aaa;
}
