.PHPLogItem {
	flex-direction: column;
}
.PHPLogItem .level {
	color: #ccc;
	font-weight: bold;
	margin-right: 10px;
	width: 150px;
}
.PHPLogItem .message {
	flex-grow: 1;
	min-width: 0;
	font-family: courier-new, courier, monospace;
	word-break: break-all;
	white-space: pre-wrap;
}

.PHPLogItem .PillButton {
	margin: 0 0 0 10px;
	width: 100px;
	opacity: 0;
	transition: opacity 150ms;
}

.PHPLogItem:hover .PillButton {
	opacity: 1;
}

.PHPLogItem .date {
	color: #999;
	width: 150px;
}
.PHPLogItem .date .time-ago {
	font-size: 12px;
	color: #bbb;
}
