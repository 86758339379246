@tailwind base;
@tailwind components;
@tailwind utilities;

#root > div {
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	height: 100%;
}

svg {
    display: inline;
}
