.GroupedLog {
	background: #222222;
	border-radius: 3px;
	padding: 0.5em;
}

.GroupedLog .Loader {
	stroke: #fff;
}

.GroupedLog__group-title {
	cursor: pointer;
	font-size: 16px;
	padding: 0.3em 0;
	line-height: 1.2;
	color: rgb( 214, 214, 214, 0.6 );
}

.GroupedLog__group-empty .GroupedLog__group-title {
	list-style: none;
	padding-left: 1.1em;
	cursor: initial;
}

.GroupedLog__group-title:hover {
	color: rgb( 214, 214, 214, 0.8 );
}

.GroupedLog__group-name {
	color: #c5c5c5;
}

.GroupedLog__group-title:hover .GroupedLog__group-name {
	color: #d6d6d6;
}

.GroupedLog__group-note {
	float: right;
}

.GroupedLog__group > .Log {
	margin-top: 0;
}
