.Modal {
	width: 90%;
	max-width: 800px;
	max-height: 90vh;
	display: flex;
	flex-direction: column;
	background-color: white;
	padding: 20px;
	border-radius: 10px;
	box-shadow: 0 2px 15px -5px rgba( 91, 105, 131, 0.50 );
}

.Modal__overlay {
	position: fixed;
	inset: 0px;
	background-color: rgba(255, 255, 255, 0.75);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100;
}

.Modal__header {
	display: grid;
	grid-template-columns: auto min-content;
	align-items: center;
	margin: -20px;
	margin-bottom: 1em;
	padding: 1em 20px 0.5em;
}

.Modal__header h2 {
    margin: 0;
    font-weight: normal;
}

.Modal__wrap {
	overflow: auto;
}
