.UnifiedDeployListItem-StatusIcon {
	display: inline-block;
	width: 16px;
	padding: 0 0.5em 0 0;
}

.UnifiedDeployListItem-StatusIcon > svg {
	vertical-align: middle;

	/* Adjust to perceptual center, rather than actual */
	margin-top: -3px;
}

.UnifiedDeployListItem-StatusIcon .ActivityIndicator {
	display: inline-block;
	padding: 0 0 0 2px !important;
	margin-right: -2px;
}

.UnifiedDeployListItem-StatusIcon--success {
	color: #1d8102;
}
.UnifiedDeployListItem-StatusIcon--failed {
	color: #E2182C;
}