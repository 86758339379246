/* .ApplicationLogs-Header__filter--error {
	--bg-color: #d11602;
}
.ApplicationLogs-Header__filter--warning {
	--bg-color: #ff9a00;
}
.ApplicationLogs-Header__filter--notice {
	--bg-color: #1f73b7;
}
.ApplicationLogs-Header__filter--log {
	--bg-color: #000;
}
.ApplicationLogs-Header__filter--success {
	--bg-color: #3FCF8E;
} */

.PinnedNotices__notice {
	/* border: 4px solid #FDDA56; */
	background: #FFEFC2;
	padding: 1em 2em;
	box-sizing: border-box;
	display: grid;
	grid-template-columns: max-content auto;
	column-gap: 1em;
}

.PinnedNotices__notice--major {
	background: #d11602;
	color: #fff;
	/* --icon-color:  */
}

.PinnedNotices__notice--minor {
	background: #ff9a00;
}

.PinnedNotices__notice--info {
	background: #1f73b7;
	color: #fff;
}

.PinnedNotices__notice a {
	color: inherit;
	text-decoration: underline;
}
