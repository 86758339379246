.XRayFilterForm {
}

.XRayFilterForm__input {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex: 1;
	column-gap: 10px;
}

.XRayFilterForm__input > div {
	display: flex;
	flex-shrink: 0;
	flex-direction: column;
}

.XRayFilterForm .filter-expression {
	flex: 1;
}

.XRayFilterForm .filter-expression input {
	font-family: var( --font-monospace );
	font-size: 0.9em;
}

.XRayFilterForm .filter-expression button {
	cursor: pointer;
}

.XRayFilterForm .SolidButton {
	align-self: flex-end;
}

.XRayFilterForm__helpers {
	display: flex;
	flex-wrap: wrap;
	column-gap: 0.5em;
	margin: 0.5em 0;
}

.XRayFilterForm__helpers button {
	font-family: var( --font-monospace );
	font-size: 10px;
}

.XRayFilterForm label {
	font-size: 12px;
	color: #666;
	margin-bottom: 5px;
}
.XRayFilterForm input {
	border-width: 1px;
	border-color: '#DDD';
	padding: 7px;
}

.XRayFilterForm__field-table {
	width: 100%;
}

.XRayFilterForm__field-table th,
.XRayFilterForm__field-table td {
	margin-bottom: 0.5em;
}
