.ServerStatusIndicator {
	width: 14px;
	height: 14px;
	border-radius: 8px;
	border: 1px solid gray;
	position: relative;
}
.ServerStatusIndicator:after {
	content: " ";
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background: gray;
	position: absolute;
	top: 2px;
	left: 2px;
}
.ServerStatusIndicator.ok {
	border-color: #5cd179;
}
.ServerStatusIndicator.ok:after {
	background: #5cd179;
}
.ServerStatusIndicator.error {
	border-color: #db4d33;
}
.ServerStatusIndicator.error:after {
	background: #db4d33;
}
