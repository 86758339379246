.EditProfileForm header {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
}
.EditProfileForm header .Avatar {
	margin-right: 10px;
}
.EditProfileForm header h2 {
	font-weight: normal;
	margin: 0;
	flex: 1;
}
.EditProfileForm header .edit-extended-profile {
	font-size: 12px;
	text-decoration: none;
}
.EditProfileForm > form > div {
	margin-bottom: 15px;
	display: flex;
	flex-direction: column;
}
.EditProfileForm label {
	font-size: 14px;
	color: #152A4E;
	margin-bottom: 5px;
}
.EditProfileForm input[type="text"], .EditProfileForm input[type="email"] {
	padding: 5px;
	line-height: 30px;
	background: #FFFFFF;
	border: 1px solid #DFE2E7;
	border-radius: 4px;
	font-size: 16px;
	color: #5B6983;
}
.EditProfileForm__version {
	font-size: 0.8em;
	text-align: center;
}
.EditProfileForm .submit {
	display: flex;
	margin: 15px 0 0;
	justify-content: space-between;
}
