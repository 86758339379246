.PillButton {
	text-align: center;
	line-height: 20px;
	align-self: inherit;
	padding: 0 10px;
	border-radius: 10px;
	font-size: 12px;
	height: 20px;
	color: white;
	cursor: pointer;
}
