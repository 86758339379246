.Instance-Reports > p {
	margin: 0 0 1em;
}

.Instance-Reports__table {
	width: 100%;
	border-collapse: collapse;
}

.Instance-Reports__table thead th {
	padding: 13px 13px;
	background: #f0f7f6;
}

.Instance-Reports__table tbody th,
.Instance-Reports__table tbody td {
	border-bottom: 1px dashed #d0d8e5;
	border-right: 1px solid #f0f7f6;
	padding: 13px 13px;
}

.Instance-Reports__table th:last-child,
.Instance-Reports__table td:last-child {
	border-right: 0;
}

.Instance-Reports__date {
	text-align: left;
}
td.Instance-Reports__date {
	font-size: 1.1em;
}

.Instance-Reports__views,
.Instance-Reports__uptime,
.Instance-Reports__responsetime {
	text-align: right;
}
td.Instance-Reports__views,
td.Instance-Reports__uptime,
td.Instance-Reports__responsetime {
	font-variant-numeric: tabular-nums;
}

.Instance-Reports__none {
	text-align: center;
}
