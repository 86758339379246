.WarningBlock {
	padding: 20px;
	background: rgba(226, 196, 24, 0.1);
	border-radius: 4px;
	font-size: 16px;
	color: rgb(126, 40, 0);
}

.WarningBlock.WarningBlockSmall {
	padding: 10px;
}
