.FormattedContent hr {
	box-sizing: content-box;
	height: 0;
	overflow: visible
}

.FormattedContent pre {
	font-family: monospace,monospace;
	font-size: 1em
}

.FormattedContent a {
	background-color: transparent
}

.FormattedContent abbr[title] {
	border-bottom: none;
	text-decoration: underline;
	text-decoration: underline dotted
}

.FormattedContent b,
.FormattedContent strong {
	font-weight: bolder
}

.FormattedContent code,
.FormattedContent kbd,
.FormattedContent samp {
	font-family: monospace,monospace;
	font-size: 1em
}

.FormattedContent small {
	font-size: 80%
}

.FormattedContent sub,
.FormattedContent sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline
}

.FormattedContent sub {
	bottom: -.25em
}

.FormattedContent sup {
	top: -.5em
}

.FormattedContent img {
	border-style: none;
	max-width: 100%;
	height: auto;
}

.FormattedContent details {
	display: block
}

.FormattedContent summary {
	display: list-item
}

.FormattedContent {
	margin-top: 1.875rem;
	line-height: 1.45;
}

.FormattedContent blockquote,
.FormattedContent figure,
.FormattedContent footer,
.FormattedContent form,
.FormattedContent h1,
.FormattedContent h2,
.FormattedContent h3,
.FormattedContent h4,
.FormattedContent h5,
.FormattedContent h6,
.FormattedContent header,
.FormattedContent iframe,
.FormattedContent li,
.FormattedContent nav,
.FormattedContent object,
.FormattedContent ol,
.FormattedContent p,
.FormattedContent section,
.FormattedContent table,
.FormattedContent ul {
	margin: 0;
	padding: 0
}

.FormattedContent article,
.FormattedContent figure,
.FormattedContent footer,
.FormattedContent header,
.FormattedContent hgroup,
.FormattedContent nav,
.FormattedContent section {
	display: block
}

.FormattedContent ol,
.FormattedContent ul {
	list-style: none
}

.FormattedContent h1,.FormattedContent h2,.FormattedContent h3,.FormattedContent h4,.FormattedContent h5,.FormattedContent h6 {
	margin-bottom: 1.875rem
}

.FormattedContent h2 {
	font-size: 1.625rem;
	font-weight: 500;
	line-height: 1.4;
	color: #152a4e
}

.FormattedContent h3 {
	font-size: 1.375rem;
	font-weight: 500;
	line-height: 1.45
}

.FormattedContent h2,.FormattedContent h3,.FormattedContent h4,.FormattedContent h5,.FormattedContent h6 {
	position: relative
}

.FormattedContent h2 a[aria-hidden],.FormattedContent h3 a[aria-hidden],.FormattedContent h4 a[aria-hidden],.FormattedContent h5 a[aria-hidden],.FormattedContent h6 a[aria-hidden] {
	content: "";
	position: absolute;
	top: 0;
	left: -1.25em;
	right: 0;
	bottom: 0
}

.FormattedContent h2 a[aria-hidden]:active .icon-link,.FormattedContent h2 a[aria-hidden]:focus .icon-link,.FormattedContent h2 a[aria-hidden]:hover .icon-link,.FormattedContent h3 a[aria-hidden]:active .icon-link,.FormattedContent h3 a[aria-hidden]:focus .icon-link,.FormattedContent h3 a[aria-hidden]:hover .icon-link,.FormattedContent h4 a[aria-hidden]:active .icon-link,.FormattedContent h4 a[aria-hidden]:focus .icon-link,.FormattedContent h4 a[aria-hidden]:hover .icon-link,.FormattedContent h5 a[aria-hidden]:active .icon-link,.FormattedContent h5 a[aria-hidden]:focus .icon-link,.FormattedContent h5 a[aria-hidden]:hover .icon-link,.FormattedContent h6 a[aria-hidden]:active .icon-link,.FormattedContent h6 a[aria-hidden]:focus .icon-link,.FormattedContent h6 a[aria-hidden]:hover .icon-link {
	fill: #5b6983;
	visibility: visible
}

.FormattedContent h2 .icon-link,.FormattedContent h3 .icon-link,.FormattedContent h4 .icon-link,.FormattedContent h5 .icon-link,.FormattedContent h6 .icon-link {
	font-size: 80%;
	top: .3em;
	width: .9em;
	height: .9em;
	left: 0;
	position: absolute;
	fill: #dfe2e7;
	transition: fill .3s linear;
	text-decoration: none;
	visibility: hidden
}

.FormattedContent [class^=wp-image] {
	border-radius: .25rem
}

.FormattedContent .wp-block-gallery {
	margin-bottom: 0
}

.FormattedContent .wp-block-gallery li {
	list-style: none;
	padding-left: 0
}

.FormattedContent .wp-block-gallery li:after {
	display: none
}

.FormattedContent pre {
	max-width: 100%;
	overflow: scroll;
	tab-size: 4
}

.FormattedContent pre>code {
	border-radius: 4px
}

.FormattedContent * {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box
}

.FormattedContent p {
	margin-bottom: 1.875rem;
	line-height: 1.5rem;
}

.FormattedContent p.alignwide {
	text-align: center
}

.FormattedContent ul {
	list-style: none;
	padding: 0
}

.FormattedContent ol,.FormattedContent ul {
	overflow: hidden;
	margin-bottom: 1.875rem
}

.FormattedContent ol {
	list-style: decimal;
	padding-left: 1.125rem
}

.FormattedContent li ol,.FormattedContent li ul {
	margin: .625rem 0
}

.FormattedContent ol li {
	display: list-item;
	position: relative;
	margin-left: 1.125rem
}

.FormattedContent ol li::marker {
	display: inline-block;
	font-variant-numeric: oldstyle-nums;
	position: absolute;
	width: .75rem;
	text-align: right
}

.FormattedContent ul li {
	display: list-item;
	position: relative;
	padding-left: 1.875rem
}

.FormattedContent ul li:after {
	content: "";
	height: .5rem;
	width: .5rem;
	background: #4667de;
	display: block;
	position: absolute;
	transform: rotate(45deg);
	top: .5rem;
	left: .125rem
}

.FormattedContent address {
	margin: 0 0 1.875rem
}

.FormattedContent pre {
	background: #e2e5e9;
	font-family: monospace;
	font-size: 1rem;
	margin-bottom: 1.875rem;
	max-width: 100%;
	overflow: auto;
	padding: 1.25rem
}

.FormattedContent big {
	font-size: 125%
}

.screen-reader-text {
	border: 0;
	clip: rect(1px,1px,1px,1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	word-wrap: normal!important
}

.FormattedContent table {
	margin: 1.5rem -.5rem;
	border-collapse: collapse
}

.FormattedContent thead {
	border-bottom: 2px solid #dfe2e7
}

.FormattedContent th {
	white-space: nowrap
}

.FormattedContent td,
.FormattedContent th {
	text-align: left;
	vertical-align: top;
	padding: .5rem 2rem .5rem .5rem
}

.FormattedContent td:last-of-type,
.FormattedContent th:last-of-type {
	padding-right: .5rem
}

.FormattedContent thead+tbody tr:nth-child(2n) {
	background: #f3f5f9
}

.FormattedContent blockquote {
	border-left: 2px solid #5b6983;
	padding-left: 1.25rem;
	margin-bottom: 1.875rem
}
