.SupportNew__title {
	width: 100%;
	min-height: 2em;
	padding: 4px 8px;
	font-size: 14px;
	line-height: 1.8;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-sizing: border-box;
}

.SupportNew__title:focus {
	border-color: #4164e6;
}

.SupportNew h3 {
	font-size: 1.2em;
	margin: 1em 0 0.5em;
}

.SupportNew__content textarea {
	border: 1px solid #ccc;
	border-radius: 4px;
	padding: 1em;
	min-height: 100px;
	font-size: inherit;
	font-family: inherit;
	width: 100%;
	box-sizing: border-box;
}

.SupportNew__content textarea:focus {
	border-color: #4164e6;
}

.SupportNew__content-uploader {
	display: grid;
	grid-template-columns: auto auto;
	align-items: baseline;
}

.SupportNew__content-uploader textarea {
	grid-column: span 2;
}

.SupportNew__content-uploader .DropUpload-status > .buttons {
	justify-content: flex-end;
}

.SupportNew__content svg {
	margin-left: 0.5em;
}

.SupportNew__content .SupportTicketMessage__attachments {
	margin-left: 0;
}

.SupportNew .field-description {
	color: #888;
	margin: 0.5em 0;
}

.SupportNew .form-buttons {
	display: block;
	margin: 0 0 30px -10px;
	text-align: right;
}

.SupportNew .form-buttons > * {
	display: inline-block;
	margin-left: 10px;
	vertical-align: middle;
}

.SupportNew .form-field {
	display: flex;
}

.SupportNew .form-field > * {
	flex: 1;
	margin-right: 3%;
}

.SupportNew .form-field > *:last-child {
	margin-right: 0;
}

.SupportNew .SelectApplication {
	display: flex;
	justify-content: space-between;
}

.SupportNew .SelectApplication > * {
	width: 49%;
}

@media(max-width: 500px) {
	.SupportNew .form-field {
		flex-direction: column;
	}
	.SupportNew .form-field > * {
		margin-right: 0;
	}
}
