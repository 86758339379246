.IpAddress {
	position: relative;
}

.IpAddress .IpAddressInformation {
	display: block;
	position: absolute;
	left: -150px;
	top: -50%;
	z-index: 2;
}
