.SupportTicketChangeEvent {
	margin-bottom: 2em;
	margin-left: -10px;
	padding: 0.5em 0 0.5em 35px;
	line-height: 1.7;
	background: rgba( 250, 245, 227, 0.596 );
}

.SupportTicketChangeEvent .Avatar {
	display: inline-block;
	margin-right: 6px;
	vertical-align: middle;
}

.SupportTicketChangeEvent__author {
	font-weight: bold;
}

.SupportTicketChangeEvent .Pill {
	font-size: inherit;
	line-height: inherit;
	padding: 3px 8px;
}
