.Support-SelectType__options {
	list-style: none;
	padding: 0;
	margin: 1em 0;
	max-width: 600px;
}

.Support-SelectType__options li {
	display: block;
	width: 100%;
	margin-bottom: 1em;
}

a.Support-SelectType__option {
	color: inherit;
	text-decoration: none;
	cursor: pointer;
	border: 1px solid #ccc;
	border-radius: 4px;
	padding: 1em;
	line-height: 1.4;

	display: flex;
	width: 100%;
	column-gap: 1em;
	align-items: center;
}

.Support-SelectType__option:hover {
	border: 1px solid #4164e6;
}

.Support-SelectType__option:hover .Support-SelectType__option-chevron {
	color: #4164e6;
}

.Support-SelectType__option-icon,
.Support-SelectType__option-chevron {
	flex-shrink: 0;
	flex-grow: 0;
}

.Support-SelectType__option-main {
	flex-grow: 1;
}

.Support-SelectType__option-main h3 {
	font-size: inherit;
	font-weight: bold;
	color: #4164e6;
	margin: 0 0 0.5em;
}

.Support-SelectType__option-main p {
	margin: 0;
}
