.UnifiedDeployListItem-QueueIndicator {
	text-align: center;
	color: rgb( 214, 214, 214, 0.6 );
}

.UnifiedDeployListItem-QueueIndicator .ActivityIndicator {
	width: 16px;
	display: inline-block;
	margin-right: 0.5em;
	vertical-align: middle;
}
