.Console-Toolbar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid rgba( 214, 214, 214, 0.1 );
}

.Console-Toolbar > div {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.Console-Toolbar > :first-child {
	margin-left: 15px;
}

.Console-Toolbar__name {
	font-size: 14px;
	font-weight: normal;
	color: inherit;
}

.Console-Toolbar button {
	background: transparent;
	border: none;
	box-shadow: none;
	padding: 0.8em 0.5em;
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}
