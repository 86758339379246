.SupportTicket {
	max-width: 60em;
}

.SupportTicket .SupportTicketMessage,
.SupportTicket .SupportTicketMessageLoading {
	margin-bottom: 10px;
}

.SupportTicket__back-link {
	margin: 0 0 1em;
}

.SupportTicket__head {
	display: grid;
	grid-template-columns: auto min-content;
	align-items: baseline;
	row-gap: 0.5em;
	border-bottom: 1px dashed #d0d8e5;
	padding: 0 0 0.5em;
}

.SupportTicket__context {
	margin: 0.6em 0 0;
	line-height: 2;
}

.SupportTicket__context > * {
	margin-right: 10px;
	display: inline-block;
}

.SupportTicket__details {
	margin: 0;
	line-height: 2;
}

.SupportTicket__details > *,
.SupportTicket__context > * {
	margin-right: 10px;
	display: inline-block;
}

.SupportTicket__details > :nth-child( n + 2 )::before,
.SupportTicket__context > :nth-child( n + 2 )::before {
	display: inline-block;
	content: "•";
	font-size: 1.5em;
	line-height: 1;
	margin-right: 10px;
	vertical-align: middle;
}

.SupportTicket__status {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	row-gap: 0.5em;
}

.SupportTicket__status .Pill {
	font-size: inherit;
	font-size: 1.1em;
	padding: 0.3em 10px;
	display: inline-block;
}

.SupportTicket__id {
	justify-self: flex-end;
	white-space: nowrap;
}

.SupportTicket__assignee .Avatar {
	display: inline-block;
	vertical-align: middle;
	margin-right: 0.2em;
}

.SupportTicket__application:nth-child( n + 2 ) {
	margin-left: 0.5em;
}

.SupportTicket .SupportTicketUpdateForm {
	margin-top: 20px;
}

.SupportTicket__closed {
	display: flex;
	justify-content: space-between;
	align-items: baseline;

	margin-left: 55px;
	padding: 10px;
	border-radius: 4px;
	background-color: rgb(233, 235, 237);
}
