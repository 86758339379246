.CronLogs .CronLogItem {
	border-bottom: 1px dashed #d0d8e5;
	padding: 10px;
}
.CronLogs .CronLogItem:nth-child(odd) {
	background-color: #fafafa;
}
.CronLogs .metrics {
	display: flex;
	flex-direction: row;
	margin-bottom: 15px;
}
.CronLogs .metrics > * {
	width: 50%;
}
