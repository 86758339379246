.Sidebar {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    padding: 15px 0 10px 0;
    width: 300px;
    color: white;
    overflow-y: auto;
    background-image: linear-gradient(0deg, #0F1A3C 0%, #273C83 100%);
	box-shadow: 0 2px 15px -5px rgba(91,105,131,0.30);
}

.Sidebar h2 {
	font-size: 19px;
}

.Sidebar a {
    text-decoration: none;
}

.Sidebar > div {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
}

h1.Sidebar__title, .Sidebar__title {
	display: flex;
	align-items: center;
	font-size: 18px;
	font-weight: 500;
	color: #fff;
	margin: 0;
	padding: 0.5em 20px 1em;
}

.Sidebar__logo {
	flex-grow: 0;
	flex-shrink: 0;
	width: auto;
	height: 1.2em;
	margin: -0.4em 0.5em 0 0;
}

.Sidebar__contents {
	display: flex;
	flex-grow: 1;
	padding-bottom: 30px;
}

.Sidebar h2 {
	margin: 1em 0 0.7em 20px;
	font-weight: normal;
}

.Sidebar h3 {
	color: #fff;
	font-size: inherit;
	line-height: 20px;
	font-weight: normal;
	margin: 8px 0 6px 25px;
}

.Sidebar h3 svg {
	margin-right: 13px;
}

.Sidebar__contents > div {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
}

.Sidebar__primary {
	flex-grow: 1;
}

.Sidebar__open-overlay,
.Sidebar__toggle,
.Sidebar__mobile-title {
	display: none;
}

@media only screen and (max-width: 1024px) {
	.Sidebar {
		width: auto;
		padding: 0;
	}

	h1.Sidebar__title, .Sidebar__title {
		background: #273C83;
		margin: 0;
		padding: 0.5em 10px;
		height: 51px;
		box-sizing: border-box;
		white-space: nowrap;
	}

	.Sidebar__mobile-title {
		display: block;
		opacity: 0.9;
		font-weight: normal;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.Sidebar__mobile-title-sep {
		margin-left: 0.5em;
		margin-right: 0.2em;
	}

	.Sidebar__contents {
		position: absolute;
		top: 51px;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 1;
		width: 300px;
		transform: translate( -320px, 0 );
		transition: transform 200ms;
		background-image: linear-gradient(0deg, #0F1A3C 0%, #273C83 100%);
		box-shadow: 0 2px 15px -5px rgba(91,105,131,0.30);
	}

	.Sidebar--expanded .Sidebar__contents {
		transform: translate( 0, 0 );
	}

	.Sidebar__toggle {
		display: block;
		float: left;
		margin: 3px 11px 0 0;
	}

	.Sidebar__open-overlay {
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}
}
