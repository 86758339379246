.DatabaseServersList .DatabaseServersListItem,
.DatabaseServersList .ServerListItemLoading,
.DatabaseServersList__item {
	align-items: center;
	margin: 0 25px;
	display: flex;
}
.DatabaseServersList .DatabaseServersListItem .type {
	font-size: 12px;
	color: #999;
}

@media (max-width: 500px) {
	.DatabaseServersList .DatabaseServersListItem, .DatabaseServersList .ServerListItemLoading {
		padding: 0;
	}
}

.DatabaseServersList__item {
	height: 55px;
	display: flex;
	flex-shrink: 0;
	flex-direction: row;
}

.DatabaseServersList__item--instance {
	margin-left: 32px;
	padding-left: 55px;
	position: relative;
	border-left: 1px solid #999;
}

.DatabaseServersList__item--instance::before {
	display: block;
	content: "";
	position: absolute;
	width: 40px;
	margin-left: -55px;
	height: 0;
	border-bottom: 1px solid #999;
}

.DatabaseServersList__item .ServerStatusIndicator {
	margin-right: 15px;
}

.DatabaseServersList__item-details {
	display: flex;
	flex-shrink: 0;
	flex-direction: column;
	width: 400px;
}

.DatabaseServersList__item-name {
	margin-bottom: 5px;
}

.DatabaseServersList__item-type {
	font-size: 12px;
	color: #475264;
}

.DatabaseServersList__item .LiveLineChart {
	width: 200px;
}
