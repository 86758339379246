.DeployListLoading .list-item {
	display: flex;
	flex-shrink: 0;
	padding: 0;
	min-height: 95px;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}
.DeployListLoading .list-item .avatar {
	width: 40px;
	height: 40px;
	margin-right: 20px;
}
.DeployListLoading .list-item .details {
	height: 40px;
	flex: 1;
}
