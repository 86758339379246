.Sidebar-Link {
	margin: 0;
	font-weight: normal;
}

.Sidebar-Link svg {
	margin-right: 13px;
	width: 16px;
	height: 16px;
}

.Sidebar-Link__link, a.Sidebar-Link__link {
	color: #fff;
	line-height: 1.2;
	display: flex;
	height: 2.3em;
	padding: 0 25px 0 20px;
	border-left: 5px solid transparent;
	align-items: center;
}

.Sidebar-Link__link:hover, a.Sidebar-Link__link:hover {
	color: #fff;
	background: rgba( 243, 245, 249, 0.05 );
}

.Sidebar-Link__link-active {
	background: rgba( 243, 245, 249, 0.10 );
}

.Sidebar-Link__link-active:hover {
	background: rgba( 243, 245, 249, 0.15 );
}
