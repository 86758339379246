.InstanceUsers-RoleSelect {
	width: 10em;
	font-size: 14px;
}

.InstanceUsers-RoleSelect__opt-desc {
	display: block;
	opacity: 0.6;
	font-size: 0.9em;
	line-height: 1.3;
	margin: 5px 0;
}
