.XRayTrace-Errors__error {
	flex-direction: column;
	margin-top: 10px;
	border-bottom: 1px solid #e1e1e1;
	padding-bottom: 10px;
}

.XRayTrace-Errors__message {
	flex-direction: row;
}

.XRayTrace-Errors__message-type {
	font-weight: bold;
	margin-right: 5px;
	color: #999;
}

.XRayTrace-Errors__message-text {
	white-space: pre-wrap;
}

.XRayTrace-Errors__source {
	padding-top: 2px;
	font-size: 12px;
	color: #999;
}
