.ActivityDashboardFilterForm {
	display: flex;
	flex-direction: column;
}
.ActivityDashboardFilterForm p {
	display: flex;
	flex-direction: column;
}
.ActivityDashboardFilterForm label {
	font-size: 14px;
	color: #152A4E;
	margin-bottom: 10px;
}
