.XRayOverview-MainChart__legend {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	margin: 0.5em 0;
	padding: 0;
}

.XRayOverview-MainChart__legend button {
	line-height: 1.6;
	padding: 0 0.3em;
	border: 1px solid transparent;
	margin-right: 0.5em;
}
