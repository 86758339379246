.PHPLog .PHPLogItem {
	border-bottom: 1px dashed #d0d8e5;
	padding: 10px;
}
.PHPLog .metrics {
	display: flex;
	flex-direction: row;
	margin-bottom: 15px;
}
.PHPLog .metrics > * {
	width: 50%;
}
