.ApplicationDeploys .branch-deploy {
	margin-bottom: 60px;
}
.ApplicationDeploys .branch-details {
	display: flex;
	align-items: center;
	flex-shrink: 0;
	flex-direction: row;
	border-radius: 4px;
	margin-top: 25px;
	margin-bottom: 20px;
}
.ApplicationDeploys .branch-details .ActivityListItem {
	flex: 1;
}
.ApplicationDeploys .branch-details .builds-select {
	flex: 1;
}

.ApplicationDeploys .branch-details .SolidButton {
	margin-left: 10px;
}
