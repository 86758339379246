.XRayTrace-FlamegraphPage__header {
	padding: 1em;
	margin-bottom: 0.5em;
	border: 1px solid rgba( 49, 130, 189, 0.2 );
	border-radius: 4px;
	border-bottom: 1px solid rgba( 49, 130, 189, 0.2 );
	display: flex;
	justify-content: space-between;
	align-items: baseline;
}

.XRayTrace-FlamegraphPage__sort {
	display: flex;
	align-items: center;
}

.XRayTrace-Flamegraph__toggle-label {
	margin-right: 0.5em;
}

.XRayTrace-FlamegraphPage__toggle {
	border: 1px solid rgba( 48, 130, 189, 0.9 );
	border-radius: 4px;
	display: flex;
	align-items: center;
}

.XRayTrace-FlamegraphPage__filter {
	border-right: 1px solid rgba( 48, 130, 189, 0.9 );
}

.XRayTrace-FlamegraphPage__filter:last-child {
	border-right: none;
}

.XRayTrace-FlamegraphPage__filter input[type=radio] {
	display: none;
}

.XRayTrace-FlamegraphPage__filter-label {
	display: block;
}

.XRayTrace-FlamegraphPage__filter svg,
.XRayTrace-FlamegraphPage__filter-label {
	padding: 0.5em;
}

.XRayTrace-FlamegraphPage__filter input[type=radio]:checked + svg,
.XRayTrace-FlamegraphPage__filter input[type=radio]:checked + .XRayTrace-FlamegraphPage__filter-label {
	background: rgba( 48, 130, 189, 0.9 );
	color: #fff;
}

.XRayTrace-FlamegraphPage__axis-label {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	margin: 0 0 0.5em;
	font-size: 11px;
	color: rgba( 49, 130, 189, 0.4 );
	border-left: 1px solid rgba( 49, 130, 189, 0.4 );
	border-right: 1px solid rgba( 49, 130, 189, 0.4 );
}

.XRayTrace-FlamegraphPage__axis-label::before {
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	z-index: 0;
	display: block;
	content: " ";
	border-top: 1px solid rgba( 49, 130, 189, 0.2 );
}

.XRayTrace-FlamegraphPage__axis-label-text {
	display: block;
	color: #666;
	background: #fff;
	padding: 0 0.5em;
	z-index: 1;
}
