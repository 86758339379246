.ApplicationLogs-Nginx__table {
	width: 100%;
}

.ApplicationLogs-Nginx__date {
	width: 13em;
}

.ApplicationLogs-Nginx__date .ApplicationLogs-Timestamp,
tbody .ApplicationLogs-Nginx__process {
	font-family: var( --font-monospace );
}

tbody .ApplicationLogs-Nginx__date {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.ApplicationLogs-Nginx__process {
	font-variant-numeric: tabular-nums;
	width: 8em;
}

.ApplicationLogs-Nginx__conn {
	display: block;
}

tbody .ApplicationLogs-Nginx__message {
	font-family: var( --font-monospace );
	font-size: 12px;
	white-space: pre-wrap;
}
