.Instance-Metrics__select {
	text-align: center;
	min-width: 110px;
	line-height: 1.6;
	border-bottom: 1px solid #273c83;
	font-size: inherit;
	padding: 0 0.5em;
	font-weight: 500;
	cursor: pointer;
}

.Instance-Metrics__select svg {
	margin-left: 0.5em;
}

.Instance-Metrics .react-datepicker {
	line-height: 1.8;
}