.InternalBlock {
	border-radius: 4px;
	background: #FFEFC2;
	padding: 1em;
	margin: 0 -0.5em;
}

.InternalBlock__title {
	display: grid;
	grid-template-columns: auto max-content;
	align-items: center;
	font-size: 1.3em;
}
