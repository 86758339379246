.ElasticSearchClusterListItem {
	display: flex;
	flex-direction: row;
	height: 90px;
	align-items: center;
	padding: 0 25px;
}
.ElasticSearchClusterListItem .ServerStatusIndicator {
	margin-right: 15px;
}
.ElasticSearchClusterListItem .server-name {
	display: flex;
	flex-direction: column;
}
.ElasticSearchClusterListItem .server-name strong {
	margin-bottom: 5px;
}
.ElasticSearchClusterListItem .server-name span {
	color: #475264;
}
.ElasticSearchClusterListItem .LiveLineChart {
	width: 200px;
}
.ElasticSearchClusterListItem .type {
	font-size: 12px;
	color: #999;
}
