.ObjectCacheServerListItem {
	display: flex;
	flex-direction: row;
}
.ObjectCacheServerListItem .ServerStatusIndicator {
	margin-right: 15px;
}
.ObjectCacheServerListItem .server-name {
	display: flex;
	flex-direction: column;
	width: 200px;
}
.ObjectCacheServerListItem .type {
	font-size: 12px;
	color: #999;
}
.ObjectCacheServerListItem .server-name strong {
	margin-bottom: 5px;
}
.ObjectCacheServerListItem .server-name span {
	color: #475264;
}
.ObjectCacheServerListItem .LiveLineChart {
	width: 200px;
}
