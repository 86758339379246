.ApplicationLogs-Header {
	padding: 1em;
	border: 1px solid rgba( 49, 130, 189, 0.2 );
	border-radius: 4px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.ApplicationLogs-Header__filters {
	display: flex;
	align-items: center;
}

.ApplicationLogs-Header__search > span {
	position: absolute;
	width: 0;
	text-indent: -9999px;
}

.ApplicationLogs-Header__search input {
	font-size: 14px;
	padding: 7px;
	width: 25em;
}

.ApplicationLogs-Header__filter {
	margin-left: 0.5em;
	--bg-color: rgba( 48, 130, 189, 0.9 );
}

.ApplicationLogs-Header__filter input[type=checkbox] {
	display: none;
}

.ApplicationLogs-Header__filter--error {
	--bg-color: #d11602;
}
.ApplicationLogs-Header__filter--warning {
	--bg-color: #ff9a00;
}
.ApplicationLogs-Header__filter--notice {
	--bg-color: #1f73b7;
}
.ApplicationLogs-Header__filter--log {
	--bg-color: #000;
}
.ApplicationLogs-Header__filter--success {
	--bg-color: #3FCF8E;
}

.ApplicationLogs-Header__filter svg {
	padding: 0.5em;
	border-radius: 4px;
}

.ApplicationLogs-Header__filter input[type=checkbox]:checked + svg {
	background: var( --bg-color );
	color: #fff;
}

.ApplicationLogs-Header__time {
	display: flex;
	align-items: center;
	column-gap: 0.5em;
}

.ApplicationLogs-Header__refresh {
	line-height: 1;
	padding: 0.5em;
	border-radius: 4px;
	background: rgb(70, 103, 222);
	color: #fff;
}
