.ActivityListItem {
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
}
.ActivityListItem .summary {
	display: flex;
	flex-shrink: 0;
	flex-direction: row;
	color: #303949;
}
.ActivityListItem .Avatar {
	align-self: center;
	margin-right: 18px;
}
.ActivityListItem .date {
	color: #152A4E;
	font-weight: normal;
}
.ActivityListItem .description {
	flex: 1;
}

.ActivityListItem .description p {
	margin-top: 5px;
	margin-bottom: 0;
	color: #5B6983;
}
.ActivityListItem__username {
	color: #4667DE;
}
.ActivityListItem .expand-button {
	border-radius: 15px;
	width: 30px;
	height: 30px;
	border: 1px solid #436CFF;
	text-align: center;
	line-height:30px;
}
.ActivityListItem .details {
	margin-top: 15px;
}
.ActivityListItem .details table table {
	border-bottom: 1px dashed #436CFF;
	padding-bottom: 10px;
}
.ActivityListItem .details table td {
	vertical-align: top;
}
.ActivityListItem .details td:last-child > code {
	word-break: break-all;
}

@media (max-width: 500px) {
	.ActivityListItem .description {
		font-size: 15px;
	}
}
