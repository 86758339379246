.ApplicationLogs-Database__table {
	width: 100%;
}

.ApplicationLogs-Database__date {
	width: 13em;
}

.ApplicationLogs-Database__date-wrap {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.ApplicationLogs-Database__command {
	width: 10em;
}

.ApplicationLogs-Database__date .ApplicationLogs-Timestamp,
tbody .ApplicationLogs-Database__command {
	font-family: var( --font-monospace );
}

.ApplicationLogs-Database__thread {
	font-variant-numeric: tabular-nums;
	text-align: right;
	width: 4em;
}

tbody .ApplicationLogs-Database__message {
	font-family: var( --font-monospace );
	font-size: 12px;
	white-space: pre-wrap;
	word-wrap: break-word;
}
