.Application {
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
}

.InstanceContainer .Application .page-title {
	margin-top: 1.5em;
}

.Application .page-title {
	margin-bottom: 20px;
	flex-direction: row;
}

.Application .site-external-link {
	margin: -4px 0 0 10px;
}

.Application .ApplicationPin {
	margin-left: 10px;
}

.Application .TabBar {
	margin-top: 55px;
}

.Application .TabBar + .TabBar {
	margin-top: 20px;
}

@media (max-width: 500px) {
	.Application .TabBar {
		margin-top: 15px;
	}
}
