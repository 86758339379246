.DeployListItem {
	padding-left: 15px;
	padding-right: 15px;
}
.DeployListItem .summary {
	display: flex;
	flex-shrink: 0;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}
.DeployListItem .summary.abandoned {
	filter: grayscale(100%);
	opacity: 0.5;
}
.DeployListItem .summary .ActivityListItem {
	flex: 1;
	margin-right: 20px;
}
.DeployListItem .summary .OutlineButton {
	margin-left: 20px;
}
.DeployListItem .summary .InfoIcon {
	font-size: 20px;
	color: #999;
	margin-left: 15px;
	margin-right: 15px;
}

.DeployListItem .TaskLog {
	margin: 20px 0;
}
