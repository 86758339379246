.AppLoader {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: #fff;
	z-index: 100;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	transition: opacity 200ms;
	opacity: 0;
	pointer-events: none;
}

.AppLoader--loading {
	opacity: 1;
	pointer-events: initial;
}
