.Productboard {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.Productboard iframe {
	margin: -20px -50px -20px -50px;
	width: calc(100% + 100px);
	flex: 1;
}
