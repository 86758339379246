.DurationSelector {
	position: relative;
}

.DurationSelector__selector-label {
	display: block;
	font-size: 12px;
	line-height: 1.6;
	color: #666;
}

.DurationSelector--flip .DurationSelector__selector-label {
	text-align: right;
}

.DurationSelector__selector {
	cursor: pointer;
}

.DurationSelector__selector svg {
	vertical-align: middle;
}

.DurationSelector__dropdown {
	position: absolute;
	width: auto;
	left: -5px;
	margin-top: 5px;
	background: white;
	border: 1px solid #ddd;
	box-shadow: 0 4px 11px rgba( 0, 0, 0, 0.1 );
	z-index: 100;
	list-style: none;
	border-radius: 4px;
	font-size: 13px;
}

.DurationSelector--flip .DurationSelector__dropdown {
	left: auto;
	right: -5px;
}

.DurationSelector__dropdown label {
	display: block;
	cursor: pointer;
	border-bottom: 3px solid transparent;
	padding: 0.5em 1em 0.5em 2em;
}

.DurationSelector__option label:hover {
	background: #DEEBFF;
}

.DurationSelector__dropdown .DurationSelector__option-selected label {
	background: #2684FF;
	color: #fff;
}

.DurationSelector__option-indicator {
	margin-left: -1.5em;
	width: 1.5em;
}

.DurationSelector__dropdown label > input[type="radio"],
.DurationSelector__manual input[type="radio"] {
	display: none;
}

.DurationSelector__manual {
	margin-top: 0.5em;
	border-top: 1px solid #ddd;
	padding: 0.5em 1em;
}

.DurationSelector__manual[aria-checked="true"] {
	background: #DEEBFF;
}

.DurationSelector__span {
	display: grid;
	grid-template-columns: auto auto min-content;
	align-items: center;
	column-gap: 0.5em;
	margin: 0.5em 0;
}

.DurationSelector__span input {
	font-size: 1em;
	padding: 0.2em 0.5em;
}

.DurationSelector__span :first-child::after {
	display: inline-block;
	content: "\002014";
}
