.ApplicationServers {
	display: flex;
	flex-shrink: 0;
	flex-direction: column;
}

.ApplicationServers h3 {
	font-size: 1em;
	font-weight: bold;
	margin: 2em 0 0.5em 25px;
}

.ApplicationServers .database-server-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: baseline;
	padding-right: 25px;
}

@media (max-width: 500px) {
	.ApplicationServers h3  {
		margin: 15px 0 0 0;
	}
}
