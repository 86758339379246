.OnCallCarouselSlide {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 16px;
}
.OnCallCarouselSlide .Avatar {
	margin-right: 15px;
	align-self: flex-start;
}
.OnCallCarouselSlide .details {
	display: flex;
	line-height: 22px;
	flex-direction: column;
}
.OnCallCarouselSlide .pagination {
	font-weight: bold;
	display: grid;
	grid-template-columns: 1fr 1fr;
	margin-top: 10px;
}
.OnCallCarouselSlide .pagination button {
	color: #4667DE;
	display: flex;
	line-height: 22px;
}
.OnCallCarouselSlide__next {
	grid-column-start: 2;
	grid-column-end: 3;
}
