.CronTasksRunning {
	margin: 0 0 15px 0;
}

.CronTasksRunning ul.counts {
	margin: 0 0 15px;
	list-style: none;
	padding: 0;
	flex-direction: row;
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid #eee;
	padding-bottom: 15px;
}

.CronTasksRunning ul.counts li strong {
	display: block;
}
