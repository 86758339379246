.Avatar {
	position: relative;
}
.Avatar img {
	display: block;
}
.Avatar .shadow {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 0;
	box-shadow: inset 0 0 3px 0 rgba(0,0,0,0.20);
	pointer-events: none;
}
