.XRayTrace-Request h4 {
	margin: 20px 0;
	font-weight: bold;
	font-size: 15px;
}

.XRayTrace-Request__headers {
	margin: 0;
	padding: 0;
}

.XRayTrace-Request__header {
	display: grid;
	grid-template-columns: 300px auto;
	margin: 0 0 0.5em;
	padding: 0 0 0.5em;
	font-family: var( --font-monospace );
	font-size: 0.9em;
	border-bottom: 1px dashed #d0d8e5;
}

.XRayTrace-Request__header-value {
	overflow-y: auto;
}
