.ApplicationLogs-Email__date {
	width: 13em;
}

.ApplicationLogs-Email__date-wrap {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.ApplicationLogs-Email__date .ApplicationLogs-Timestamp {
	font-family: var( --font-monospace );
}

.ApplicationLogs-Email__recipient {
	width: 16em;
	overflow: hidden;
}

.ApplicationLogs-Email__message-subject,
.ApplicationLogs-Email__message-excerpt {
	display: block;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.ApplicationLogs-Email__message-subject {
	font-weight: bold;
}

.ApplicationLogs-Email__message-excerpt code {
	font-size: 0.8em;
}

.ApplicationLogs-Email__detail h3 {
	margin: 1em 0 0.5em;
	font-weight: bold;
}

table.ApplicationLogs-Email__detail-headers {
	width: 100%;
	box-sizing: border-box;
}

.ApplicationLogs-Email__detail-headers tr > * {
	border: 1px solid #aaa;
	border-left-style: none;
	border-right-style: none;
}

.ApplicationLogs-Email__detail-headers tr > :first-child {
	border-left-style: solid;
}

.ApplicationLogs-Email__detail-headers tr > :last-child {
	border-right-style: solid;
}

.ApplicationLogs-Email__detail-headers th,
.ApplicationLogs-Email__detail-headers td {
	padding: 0.5em;
	vertical-align: top;
}

.ApplicationLogs-Email__detail-headers th {
	font-weight: normal;
	text-align: left;
}

.ApplicationLogs-Email__detail-headers code {
	font-size: 0.8em;
}

.ApplicationLogs-Email__detail-status {
	font-weight: bold;
}

.ApplicationLogs-Email__detail-status svg {
	margin-right: 0.5em;
}

.ApplicationLogs-Email__detail-status-error svg {
	color: #d11602;
}

.ApplicationLogs-Email__detail-status-success svg {
	color: #3FCF8E;
}

.ApplicationLogs-Email__detail-error,
.ApplicationLogs-Email__detail-body {
	background: #fff;
	padding: 1em;
	overflow: auto;
}

.ApplicationLogs-Email__detail a {
    color: #4667de;
    text-decoration: initial;
    transition: color .12s linear;
}
