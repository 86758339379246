.InstanceMetrics-Graph__tooltip {
	/* margin-left: -50%;
	margin-right: 50%; */
	transform: translate( -50%, 0) translate( -9px, 0 );
	position: relative;
	background: #fff;
	box-shadow: 0 2px 15px 0 rgba( 91, 105, 131, 0.30 );
	border-radius: 3px;
	padding: 5px;
	text-align: center;
	z-index: 1;
}

.InstanceMetrics-Graph__tooltip::before {
	position: absolute;
	top: -15px;
	left: calc( 50% - 10px );
	content: " ";
	display: block;
	width: 0;
	border: 10px solid transparent;
	border-bottom: 10px solid #fff;
	z-index: 0;
}

.InstanceMetrics-Graph__tooltip-value {
	margin: 0.5em 0;
}

.InstanceMetrics-Graph__tooltip-date {
	margin: 0.3em 0;
	font-size: 0.9em;
}

.InstanceMetrics-Graph__total {
	font-size: 1.7em;
}

.InstanceMetrics-Graph__total-value {
	color: #4667DE;
	font-weight: bold;
}
