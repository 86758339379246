.Tooltip__arrow,
.Tooltip__arrow::before {
	position: absolute;
	width: 8px;
	height: 8px;
	background: inherit;
}

.Tooltip__arrow {
	visibility: hidden;
}

.Tooltip__arrow::before {
	visibility: visible;
	content: '';
	transform: rotate(45deg);
}

.Tooltip__popper[data-popper-placement^='top'] > .Tooltip__arrow {
	bottom: -4px;
}

.Tooltip__popper[data-popper-placement^='bottom'] > .Tooltip__arrow {
	top: -4px;
}

.Tooltip__popper[data-popper-placement^='left'] > .Tooltip__arrow {
	right: -4px;
}

.Tooltip__popper[data-popper-placement^='right'] > .Tooltip__arrow {
	left: -4px;
}
