.IpAddressInformation {
	width: 150px;
	height: 80px;
	background: white;
	border-radius: 2;
	box-shadow: 0 0 5px rgba( 0, 0, 0, .4 );
	padding: 10px;
}

.IpAddressInformation .org {
	font-weight: bold;
}
