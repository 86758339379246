.Login {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.Login p {
	margin: 16px 0;
}

.Login .mfa-providers label {
	display: block;
}
.Login .mfa-providers label input {
	margin-right: 5px;
}

.Login input[type="text"], .Login input[type="password"] {
	padding: 5px;
	line-height: 30px;
	background: #FFFFFF;
	border: 1px solid #DFE2E7;
	border-radius: 4px;
	font-size: 16px;
	color: #5B6983;
	min-width: 300px;
	display: block;
}

.Login .mfa-providers input[type="text"] {
	margin-top: 10px;
}

.Login .Login__forgot-password {
	text-align: right;
	min-width: 300px;
	margin-top: 0;;
}
