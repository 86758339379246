.WebServersList .WebServersListItem, .WebServersList .ServerListItemLoading {
	align-items: center;
	padding: 0 25px;
}
.WebServersList .WebServersListItem .type {
	font-size: 12px;
	color: #999;
}

@media (max-width: 500px) {
	.WebServersList .WebServersListItem, .WebServersList .ServerListItemLoading {
		padding: 0;
	}
}
