.ObjectCacheServerList .ObjectCacheServerListItem {
	height: 90px;
	align-items: center;
	padding: 0 25px;
}

@media (max-width: 500px) {
	.ObjectCacheServerList .ObjectCacheServerListItem {
		padding: 0;
	}
}
