.ErrorBlock {
	padding: 20px;
	background: rgba(226,24,44,0.10);
	border-radius: 4px;
	font-size: 16px;
	color: #D90015;
}

.ErrorBlock.ErrorBlockSmall {
	padding: 10px;
}
