.Region .page-title {
	margin-bottom: 20px;
	flex-direction: row;
}

.Region .TabBar {
	margin-top: 40px;
}

.Region table {
	width: 100%;
	margin-top: 20px;
}

.Region table thead {
	background-color: #EEE;
	font-weight: bold;
}

.Region table td {
	padding: 5px;
}
