.App {
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	height: 100%;
	--font-monospace: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono", "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro", "Fira Mono", "Droid Sans Mono", "Courier New", monospace;
	background: #fff;
	font-size: 15px;
	margin: 0;
	color: #303949;
}

.App * {
	box-sizing: content-box;
}

.App .horizontal {
	display: flex;
	flex-shrink: 0;
	flex-direction: row;
	flex: 1;
	overflow: hidden;
	justify-content: stretch;
	align-items: stretch;
}
.App .page-outer {
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	flex: 1;
	overflow: auto;
}

.App .page {
	flex: 1;
	padding: 20px 50px;
	margin: 0 auto;
	width: 100%;
	box-sizing: border-box;
}
@media (max-width: 500px) {
	.App .page {
		padding: 16px;
	}
}
@media only screen and (max-width: 1024px) {
	.App .horizontal {
		flex-direction: column;
	}

	.App .page {
		padding: 10px 20px;
	}
}
@media (min-width: 1250px) {
	.App .page {
		min-width: 850px;
	}
}
.App .RunningTasksDropDown {
	width: 50%;
	margin-left: 25%;
	position: absolute;
	top: 56px;
	background: white;
}

.App a {
	color: #4667DE;
	text-decoration: initial;
	transition: color 120ms linear;
}
.App a:hover {
	color: rgb(19, 41, 121);
}

.App img {
	max-width: 100%;
	/*or width: 100%; if so the above line won't be required */
	height: auto;
}

.App h1 {
	font-size: 30px;
	font-weight: bold;
	margin: 20px 0;
	line-height: normal;
}

.App h2 {
	line-height: normal;
	font-size: 22.5px;
	margin: 18px 0;
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(-360deg);
	}
}

@keyframes placeHolderShimmer {
	0% {
		background-position: -468px 0;
	}
	100% {
		background-position: 468px 0;
	}
}

.App .animated-background {
	animation-duration: 1s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-name: placeHolderShimmer;
	animation-timing-function: linear;
	background: #f6f7f8;
	background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
	background-size: 800px 104px;
	position: relative;
}

.App pre {
	white-space: pre-wrap;
}
