.InstanceUsers > .ActivityListItemLoading {
	margin-bottom: 0.5em;
}

.Instance-Users__users-list {
	list-style: none;
	padding: 0;
	margin: 0.5em 0 3em;
	max-width: 40em;
}

.InstanceUsers__no-permission {
	max-width: 40em;
	line-height: 1.4;
}
