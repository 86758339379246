.SortableTable {
	border-collapse: collapse;
}

.SortableTable--dense {
	font-size: 13px;
	line-height: 2.4;
}

.SortableTable--fixed {
	table-layout: fixed;
}

.SortableTable thead {
	position: sticky;
	top: 0;
	z-index: 10;
	background: #fff;
}

.SortableTable--lined td,
.SortableTable--lined th {
	border-right: 1px solid #DFE2E7;
}

.SortableTable td:last-child,
.SortableTable th:last-child {
	border-right: none;
}

.SortableTable thead th {
	border-bottom: 1px solid #eee;
}

.SortableTable thead th button {
	display: flex;
	cursor: pointer;
	font: inherit;
	width: 100%;
	justify-content: space-between;
	align-items: center;
}
.SortableTable:not( .SortableTable--dense ) th button {
	padding: 0.6em 0.4em;
}

.SortableTable th button span {
	font-size: 0.7em;
	color: #999;
}

.SortableTable--dense td:first-child,
.SortableTable--dense th:first-child {
	padding-left: 5px;
}

.SortableTable--dense td:last-child,
.SortableTable--dense th:last-child {
	padding-right: 5px;
}

.SortableTable tbody tr {
	--summary-bg: transparent;
	--summary-bg-alt: rgba( 0, 0, 0, 0.03 );
	--summary-bg-hover: rgba( 70, 103, 222, 0.1 );
}

.SortableTable tbody tr td,
.SortableTable tbody tr th {
	background: var( --summary-bg );
}

.SortableTable tbody tr:nth-child( odd ) td,
.SortableTable tbody tr:nth-child( odd ) th {
	background: var( --summary-bg-alt );
}

.SortableTable tbody tr:hover td,
.SortableTable tbody tr:hover th {
	background: var( --summary-bg-hover );
}

.SortableTable:not( .SortableTable--dense ) td {
	padding: 0.6em 0.4em;
}
