.FilterLogs {
	margin: 0 0 10px 0;
	padding-bottom: 10px;
	display: flex;
	flex-direction: row;
}
.FilterLogs form {
	flex-direction: row;
	display: flex;
	flex: 1;
}
.FilterLogs form input[type="submit"] {
	cursor: pointer;
	background-color: #eee;
	border: 1px solid #999;
	padding: 0 5px;
}
.FilterLogs .search {
	flex: 1;
	margin-right: 5px;
	border: 1px solid #999;
	padding: 2px;
}
.FilterLogs .filter-status,
.FilterLogs .filter-after {
	flex-direction: row;
}
.FilterLogs .filter-status > *,
.FilterLogs .filter-after > * {
	margin-right: 5px;
}

.FilterLogs .filter-status input,
.FilterLogs .filter-after input {
	width: 100px;
	border: 1px solid #999;
	padding: 2px;
}

@media(max-width: 500px) {
	.FilterLogs form {
		flex-wrap: wrap;
	}
	.FilterLogs .search {
		flex-basis: 100%;
	}
}
