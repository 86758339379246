.InstanceUsers-User {
	display: flex;
	width: 100%;
	flex-shrink: 0;
	flex-direction: row;
	column-gap: 18px;
	align-items: center;
	color: #303949;
	margin-bottom: 1em;
}

.InstanceUsers-User .Avatar {
	align-self: center;
}

.InstanceUsers-User__primary {
	flex-grow: 1;
	flex-shrink: 1;
	overflow: hidden;
	line-height: 1.3;
}

.InstanceUsers-User__name,
.InstanceUsers-User__email {
	display: block;
	max-width: 100%;
	text-overflow: ellipsis;
}

.InstanceUsers-User__email {
	font-size: 0.9em;
	opacity: 0.7;
}

.InstanceUsers-User__actions {
	flex-grow: 0;
	flex-shrink: 0;
	display: flex;
	column-gap: 0.8em;
}

.InstanceUsers-User__delete svg {
	vertical-align: middle;
	color: #ed5f09;
}

.InstanceUsers-User__role {
	color: #5B6983;
}
