.Pill {
	text-align: center;
	line-height: 18px;
	align-self: inherit;
	padding: 1px 5px 1px 5px;
	border-radius: 5px;
	font-size: 12px;
	height: 20px;
	color: white;
	white-space: nowrap;
}
