.ApplicationShell__start {
	height: 200px;
	margin: 1em 0;
	background: #222;
	border-radius: 3px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.ApplicationShell__error {
	color: #d3d3d3;
	margin: 1em;
}
