.Pagination {
	display: grid;
	margin: 1rem 0;
	grid-template-columns: 1fr auto 1fr;
}

.Pagination button {
	color: #4667DE;
	cursor: pointer;
	text-decoration: underline;
	transition: color 120ms linear;
}

.Pagination button:hover {
	color: rgb( 19, 41, 121 );
}

.Pagination button:disabled {
	color: #aaa;
	cursor: auto;
}

.Pagination button svg {
	vertical-align: middle;
}

.Pagination__newer {
	text-align: right;
}