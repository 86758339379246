.CronLogItem {
	display: flex;
	flex-direction: column;
}

.CronLogItem .time-ago {
	color: #bbb;
	font-size: 12px;
}

.CronLogItem ul {
	list-style: none;
	padding: 0;
	margin: 0;
}
.CronLogItem ul li {
	width: 50%;
	float: left;
}
.CronLogItem ul li strong {
	display: block;
}
.CronLogItem ul li pre {
	white-space: pre-wrap;
	word-wrap: break-word;
}
