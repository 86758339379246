.RunningTask {
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	color: #303949;
}
.RunningTask .preview {
	display: flex;
	flex-shrink: 0;
	flex-direction: row;
}
.RunningTask .preview .description {
	flex: 1;
}
.RunningTask .preview .description h5 {
	font-size: 15px;
	margin: 0 0 5px 0;
}
.RunningTask .preview .expand-toggle {
	font-size: 32px;
	width: 32px;
	height: 32px;
	border-radius: 16px;
	border: 1px solid #3FCF8E;
	color: #3FCF8E;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 50px;
	cursor: pointer;
}
.RunningTask .details {
	margin-top: 30px;
}
.RunningTask .details .progress {
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	margin-bottom: 30px;
	font-weight: bold;
	color: #3FCF8E;
	flex-direction: row;
	align-items: center;
}
.RunningTask .details .progress .rc-progress-line {
	margin-left: 5px;
	flex: 1;
	height: 12px;
}

.RunningTask .TaskLog {
	margin-bottom: 20px;
}

.RunningTask .button-row {
	display: flex;
}

.RunningTask .button-row button {
	margin-left: auto;
}
