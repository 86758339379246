.BlogPost-Content {
	padding: 3rem 0;

	display: grid;
	grid-template-columns: 200px auto;
	position: relative;
}

/* Timeline on left */
.BlogPost-Content::before {
	content: " ";
	background: rgba( 91, 105, 131, .3 );
	position: absolute;
	top: 0;
	bottom: 0;
	left: 14px;
	width: 2px;
}

/* Start the line from the first avatar */
.BlogPost-Content:first-of-type::before {
	top: 3rem;
}

/* Hide the timeline for singles */
.BlogPost-Content:first-of-type:last-of-type::before {
	display: none;;
}

.BlogPost-Content .Blog-Author {
	align-self: flex-start;
}

.BlogPost-Content__main {
	box-shadow: 0 2px 15px -5px rgba( 91, 105, 131, .3 );
	border-radius: 3px;
	padding: 2rem;
	max-width: 80ch;
	overflow: hidden;
}

.App .BlogPost-Content__title {
	margin-top: 0;

	font-size: 1.625rem;
	line-height: 30px;
	color: #152A4E;
	font-weight: 500;
}
