.SupportTicketReply {
	padding: 10px;
	margin-left: -10px;
}

.SupportTicketReply.private {
	background: rgba( 250, 245, 227, 0.596 );
}

.SupportTicketReply__content {
	line-height: 22px;
	margin-left: 55px;
	margin-top: -10px;
	display: flex;
	flex-direction: column;
}

.SupportTicketReply__content-uploader {
	display: grid;
	grid-template-columns: auto auto;
	align-items: baseline;
}

.SupportTicketReply__content-uploader textarea {
	grid-column: span 2;
}

.SupportTicketReply__content-uploader .DropUpload-status > .buttons {
	justify-content: flex-end;
}

.SupportTicketReply .SupportTicketMessage__attachments {
	margin-left: 0;
}

.SupportTicketReply__followers {
	display: flex;
	align-items: center;
	margin: 0;
}

.SupportTicketReply__followers > :first-child {
	margin-right: 0.5em;
	flex-grow: 0;
}

.SupportTicketReply__followers-input {
	flex-grow: 1;
}

.SupportTicketReply textarea {
	border: 1px solid #ccc;
	border-radius: 4px;
	padding: 1em;
	min-height: 100px;
	margin: 10px 0;
	font-size: inherit;
	font-family: inherit;
}

.SupportTicketReply textarea:focus {
	border-color: #4164e6;
}

.SupportTicketReply .ErrorBlock {
	margin-bottom: 1em;
}

.SupportTicketReply__footer {
	margin-bottom: 10px;
	display: flex;
	align-items: baseline;
	justify-content: flex-end;
}

.SupportTicketReply__markdown svg {
	margin-left: 0.5em;
}

.SupportTicketReply__footer .SolidButton {
	align-self: baseline;
	margin-left: 10px;
}

@media(max-width: 500px) {
	.SupportTicketReply__content {
		margin-left: 0;
	}

	.SupportTicketReply__footer {
		flex-direction: column;
		align-items: flex-start;
		row-gap: 0.5em;
	}

	.SupportTicketReply__footer .SolidButton {
		margin-left: 0;
	}

	.SupportTicketReply__markdown {
		order: 10;
	}
}
