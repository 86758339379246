.PersonalDataCollectionOptIn {
	position: fixed;
	background: white;
	border-radius: 15px;
	box-shadow: grey 0 0 15px;
	width: 600px;
	bottom: 30px;
	z-index: 2;
	left: 50%;
	margin-left: -300px;
	padding: 15px;
	font-size: 12px;
	color: #666;
	line-height: 16px;
	display: flex;
	align-items: center;
	transition: 500ms ease-out;
	opacity: 0;
	transform: translate(0,30px);
}

.PersonalDataCollectionOptIn.PersonalDataCollectionOptIn__shown {
	transform: translate(0,0);
	opacity: 1;
}

.PersonalDataCollectionOptIn .PillButton {
	margin-left: 5px;
}

@media (max-width: 500px) {
	.PersonalDataCollectionOptIn {
		width: auto;
		left: 30px;
		right: 30px;
		margin-left: 0;
	}
}
