.Spin {
	animation-name: spinner;
	animation-duration: 3s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	transform-origin: 50% 50%;
	display: inline-block;
}

.Spin > * {
	display: block;
}
