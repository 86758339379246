.InstanceUsers-InviteForm {
	width: 30em;
}

.InstanceUsers-InviteForm__input {
	font-size: 14px;
	color: #152A4E;
	margin-bottom: 15px;
	display: flex;
	flex-direction: column;
	width: 100%;
}

.InstanceUsers-InviteForm__input > span {
	display: block;
	margin-bottom: 5px;
}

.InstanceUsers-InviteForm__input input[type="text"],
.InstanceUsers-InviteForm__input input[type="email"] {
	padding: 5px 10px;
	box-sizing: border-box;
	line-height: 30px;
	background: #FFFFFF;
	border: 1px solid #DFE2E7;
	border-radius: 4px;
	font-size: 14px;
	color: #5B6983;
	width: 100%;
}

.InstanceUsers-InviteForm__input .InstanceUsers-RoleSelect {
	width: 100%;
}

.InstanceUsers-InviteForm .ErrorBlock {
	margin-bottom: 0.5em;
}
