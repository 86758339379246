.TabBar {
	display: flex;
	flex-shrink: 0;
	flex-direction: row;
	overflow-x: auto;
}

.TabBar .TabBarItem {
	flex-grow: 1;
	text-decoration: none;
}
