.ApplicationShell-Sessions > .ActivityListItemLoading {
	margin: 26px 13px;
}

.ApplicationShell-Sessions > h3 {
	font-size: 15px;
	font-weight: normal;
	text-transform: uppercase;
	margin-top: 40px;
}

.ApplicationShell-Sessions > ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
