.SupportTicketMessageHeader {
	display: flex;
}

.SupportTicketMessageHeader__details {
	margin-left: 15px;
	color: #666;
	height: 40px;
	line-height: 40px;
	margin-bottom: 0.5em;
	flex-grow: 1;
}

.SupportTicketMessageHeader__details > * {
	margin-right: 10px;
}

.SupportTicketMessageHeader__details > :nth-child( n + 2 )::before {
	display: inline-block;
	content: "•";
	margin-right: 10px;
}

.SupportTicketMessageHeader__details .SupportTicketMessageHeader__no-separator::before {
	display: none;
	margin-left: 10px;
}

.SupportTicketMessageHeader__details .Pill {
	display: inline-block;
}

.SupportTicketMessageHeader__details .Pill input {
	margin-top: -2px;
}

.SupportTicketMessageHeader__source svg {
	vertical-align: text-top;
}

.SupportTicketMessageHeader__show-ccs {
	align-self: center;
}

@media(max-width: 500px) {
	.SupportTicketMessageHeader__details {
		height: auto;
		min-height: 32px;
	}

	.SupportTicketMessageHeader .Avatar {
		width: 32px !important;
		height: 32px !important;
		min-width: 32px !important;
		min-height: 32px !important;
	}
}
