.UnifiedDeployListItem-Deploy {
	margin-bottom: 0.5em;
}

.UnifiedDeployListItem-Deploy__summary {
	display: flex;
	align-items: center;
}

.UnifiedDeployListItem-Deploy__expando {
	color: #5B6983;
	margin-right: 9px;
	cursor: pointer;
}

.UnifiedDeployListItem-Deploy__expando svg {
	vertical-align: middle;
}

.UnifiedDeployListItem-Deploy__summary .Avatar {
	margin-right: 9px;
}

.UnifiedDeployListItem-Deploy__byline {
	flex-grow: 1;
}

.UnifiedDeployListItem-Deploy__status {
	margin-left: 9px;
	margin-right: 9px;
}

.UnifiedDeployListItem-Deploy .UnifiedDeployListItem-DeploySteps {
	margin: 1em 0;
}

.UnifiedDeployListItem-Deploy .BuildPhaseDetailsTable {
	margin-left: 33px;
}
