.Flamegraph-Tooltip {
	min-height: 100px;
	width: 600px;
	z-index: 1000;
	max-width: 100vw;
	box-sizing: border-box;
	position: fixed;
	top: 0;
	left: 0;
	pointer-events: none;
	background: #fff;
	border: 1px solid rgba( 49, 130, 189, 0.2 );
	border-radius: 4px;
	box-shadow: 0 1px 3px rgba( 0, 0, 0, 0.3 );
	font-size: 12px;
	transition: opacity 50ms;
}

.Flamegraph-Tooltip > header,
.Flamegraph-Tooltip__timing,
.Flamegraph-Tooltip__component {
	border-bottom: 1px solid rgba( 49, 130, 189, 0.2 );
}

.Flamegraph-Tooltip > header {
	display: flex;
	justify-content: space-between;
}

.Flamegraph-Tooltip p {
	margin: 0;
}

.Flamegraph-Tooltip__name {
	flex-shrink: 1;
	font-weight: bold;
	overflow: hidden;
	padding: 10px;
}

.Flamegraph-Tooltip__of-total {
	border-left: 1px solid rgba( 49, 130, 189, 0.2 );
	padding: 10px 10px 10px 0.5em;
	margin-left: 0.5em;
}

.Flamegraph-Tooltip__timing {
	display: grid;
	align-items: center;
	width: 100%;
	padding: 1em 10px;

	grid-template-columns: 150px auto auto;
	column-gap: 0.5em;
}

.Flamegraph-Tooltip__timer {
	grid-column: 1;
	grid-row: 1 / 3;
	align-self: flex-end;

	width: 100%;
	height: 1em;
	background: rgb( 70, 103, 222, 0.4 );
	border-radius: 3px;
	overflow: hidden;
}

.Flamegraph-Tooltip__timer::-webkit-meter-bar,
.Flamegraph-Tooltip__timer::-moz-meter-bar {
	background: #132979;
	position: absolute;
	height: 100%;
}

.Flamegraph__Tooltip-title {
	grid-row: 1;
	font-weight: bold;
}

.Flamegraph-Tooltip__details {
	padding: 1em;
	display: grid;
	grid-template-columns: max-content auto;
	column-gap: 1em;
}

.Flamegraph-Tooltip__details > dt {
	grid-column: 1;
	color: #666;
}

.Flamegraph-Tooltip__details > dd {
	grid-column: 2;
}
