.SupportTicket-Followers {
	font-size: 0.8em;
	color: #666;
	padding: 0.5em 0;
	border-bottom: 1px dashed #d0d8e5;
}

.SupportTicket-Followers__reason {
	margin-right: 0.5em;
}
