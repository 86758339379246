.XRayTrace-Timeline {
	border: 1px solid rgba( 49, 130, 189, 0.2 );
	border-radius: 4px;
}

.XRayTrace-Timeline__header {
	padding: 1em;
	border-bottom: 1px solid rgba( 49, 130, 189, 0.2 );
	display: flex;
	justify-content: space-between;
	align-items: baseline;
}

.XRayTrace-Timeline__filters {
	display: flex;
	align-items: center;
}

.XRayTrace-Timeline__search > span {
	position: absolute;
	width: 0;
	text-indent: -9999px;
}

.XRayTrace-Timeline__search input {
	font-size: 14px;
	padding: 7px;
}

.XRayTrace-Timeline__filter {
	margin-left: 0.5em;
}

.XRayTrace-Timeline__filter input[type=checkbox] {
	display: none;
}

.XRayTrace-Timeline__filter svg {
	padding: 0.5em;
	border-radius: 4px;
}

.XRayTrace-Timeline__filter input[type=checkbox]:checked + svg {
	background: rgba( 48, 130, 189, 0.9 );
	color: #fff;
}

.XRayTrace-Timeline__entries {
	position: relative;
}

.XRayTrace-Timeline__entry {
	position: relative;
}

.XRayTrace-Timeline__entry--selectable {
	cursor: pointer;
}

.XRayTrace-Timeline__entry.XRayTrace-Timeline__entry--selectable:hover {
	background: rgba( 49, 130, 189, 0.2 );
}

.XRayTrace-Timeline__entry--selectable .XRayTrace-Timeline__entry-inner {
	cursor: pointer;
}

.XRayTrace-Timeline__entry-inner {
	position: relative;
	height: 16px;
	padding: 8px 0;
}

.XRayTrace-Timeline__entry:nth-child( even ) {
	background: rgba( 49, 130, 189, 0.1 );
}

.XRayTrace-Timeline__entry-type-db {
	color: #3182bd;
}

.XRayTrace-Timeline__entry-type-remote {
	color: #5cd179;
}

.XRayTrace-Timeline__timer {
	position: absolute;
	width: 100%;
	background: rgba(49, 130, 189, 0.4);
	background: currentColor;
	border-radius: 2px;
	top: 8px;
	bottom: 8px;
}

.XRayTrace-Timeline__detail {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 100%;
	margin-left: 10px;

	width: 300px;
	display: flex;
	align-items: center;
	column-gap: 10px;
}

.XRayTrace-Timeline__entry-flip .XRayTrace-Timeline__detail {
	left: auto;
	right: 100%;
	margin-left: 0;
	margin-right: 10px;
}

.XRayTrace-Timeline__entry-inside .XRayTrace-Timeline__detail {
	left: auto;
	right: auto;
	margin-left: auto;
	justify-content: center;
	color: #fff;
}

.XRayTrace-Timeline__entry-name {
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 14px;
	font-size: 10px;
	font-family: var( --font-monospace );
	white-space: nowrap;
}

.XRayTrace-Timeline__entry-time {
	flex-shrink: 0;
	font-size: 12px;
}

.XRayTrace-Timeline__request-end {
	position: absolute;
	top: 0;
	bottom: 0;
	border-right: 1px dotted #aaa;
}

.XRayTrace-Timeline__tooltip {
	position: absolute;
	z-index: 1;
	top: 100%;
	left: 1em;
	right: 1em;
	margin-top: 6px;
	cursor: auto;
	background: #fff;
	color: #303949;
	border: 1px solid rgba( 49, 130, 189, 0.2 );
	border-radius: 4px;
	box-shadow: 0 1px 3px rgba( 0, 0, 0, 0.3 );
}

.XRayTrace-Timeline__tooltip > * {
	border-bottom: 1px solid rgba( 49, 130, 189, 0.2 );
	margin: 0;
	padding: 0.5rem;
}

.XRayTrace-Timeline__tooltip-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.XRayTrace-Timeline__tooltip-title {
	line-height: 1.4;
	word-wrap: break-word;
	background-color: rgba(27,31,35,.05);
	border-radius: 3px;
	font-size: 85%;
	padding: 0.5rem !important;
}

.XRayTrace-Timeline__tooltip-duration {
	display: flex;
	padding: 0;
}

.XRayTrace-Timeline__tooltip-duration > * {
	margin: 0;
	padding: 0.5rem;
	border-right: 1px solid rgba( 49, 130, 189, 0.2 );
}

.XRayTrace-Timeline__tooltip-details p {
	margin: 0.5em 0;
}

.XRayTrace-Timeline__tooltip-details code {
	background-color: rgba(27,31,35,.05);
	border-radius: 3px;
	font-size: 85%;
	margin: 0;
	padding: .2em .4em;
}
