.page .XRayOverview-SummaryList {
	margin: 1em -30px;
}

.XRayOverview-SummaryList .WarningBlock {
	margin-bottom: 15px;
}

.XRayOverview-SummaryList__table {
	width: 100%;
}

.XRayOverview-SummaryList__col--method {
	width: 4em;
}

.XRayOverview-SummaryList__col--status {
	width: 4em;
}

.XRayOverview-SummaryList__col--date {
	width: 16%;
}

.XRayOverview-SummaryList__col--response-time {
	width: 5em;
	font-variant-numeric: tabular-nums;
}

.XRayOverview-SummaryList__col--url {
	min-width: 50%;
}

.XRayOverview-SummaryList__col--client-ip {
	width: 11%;
}

.XRayOverview-SummaryList__col--client-ip .IpAddress__short {
	display: block;
	width: 8em;
	text-overflow: ellipsis;
	overflow: hidden;
}

.XRayOverview-SummaryList .XRayOverView-SummaryList__summary--error {
	--summary-bg: rgb( 255, 154, 0, 0.2 );
	--summary-bg-alt: rgb( 255, 154, 0, 0.2 );
	--summary-bg-hover: rgb( 255, 154, 0, 0.3 );
}

.XRayOverview-SummaryList .XRayOverView-SummaryList__summary--fault {
	--summary-bg: rgb( 209, 22, 2, 0.2 );
	--summary-bg-alt: rgb( 209, 22, 2, 0.2 );
	--summary-bg-hover: rgb( 209, 22, 2, 0.3 );
}

.XRayOverview-SummaryList__col--url {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.XRayOverView-SummaryList__total {
	color: #666;
	text-align: right;
	font-size: 12px;
}
