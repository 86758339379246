.Blog-Author {
	width: 100%;
	display: grid;
	grid-template-columns: min-content auto;
}

.Blog-Author > * {
	display: block;
	grid-column: 2 / 3;
	line-height: 30px;
}

.Blog-Author .Avatar {
	margin-right: 10px;
	grid-column: 1 / 2;
}

.Blog-Author__name {
	font-weight: bold;
}
