.ImportExportActionBlock {
	background: #f5f6f9;
	border-radius: 4px;
	padding-top: 1em;
}
.ImportExportActionBlock__actions {
	display: flex;
	flex-shrink: 0;
	flex-direction: row;
}
.ImportExportActionBlock__action {
	display: flex;
	flex-shrink: 0;
	flex-direction: column;
	flex: 1;
	margin: 32px 0;
	padding: 15px 10px;
	border-right: 1px solid #d0d8e5;
	align-items: center;
}
.ImportExportActionBlock__action:last-child {
	border-right: none;
}
.ImportExportActionBlock h4 {
	text-transform: uppercase;
	font-weight: normal;
	font-size: 15px;
	margin: 0;
}
.ImportExportActionBlock p {
	color: #808fa8;
	text-align: center;
	margin: 0.5em 0;
}
.ImportExportActionBlock .buttons {
	display: flex;
	flex-direction: row;
}
.ImportExportActionBlock .buttons > * {
	margin-right: 5px;
}
