.BackupListItem {
	display: flex;
	flex-shrink: 0;
	flex-direction: row;
	justify-content: space-between;
}
.BackupListItem .backup-details {
	margin-right: 20px;
}
.BackupListItem .backup-details a {
	color: black;
}
.BackupListItem .backup-info {
	display: block;
}

.BackupListItem .backup-info span {
	margin-right: 10px;
}

.BackupListItem .pill {
	background: #DDD;
	border-radius: 2px;
	padding: 1px 4px;
	font-size: 11px;
}
