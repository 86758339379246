.TabBarItem, a.TabBarItem {
	color: #152A4E;
	font-size: 16px;
	border-bottom: 4px solid transparent;
	height: 20px;
	padding: 0 0.8% 14px 0.8%;
	line-height: 20px;
	vertical-align: text-top;
	display: flex;
	justify-content: center;
	text-decoration: none;
	overflow: hidden;
}
.TabBarItem.active,
.TabBarItem:hover {
	border-bottom-color: #3FCF8E;
}
.TabBarItem.active {
	font-weight: 500;
}
.TabBarItem .name {
	text-overflow: hidden;
	overflow: hidden;
	white-space: nowrap;
}

@media (max-width: 500px) {
	.TabBarItem {
		font-size: 14px;
	}
}
