.ImportApplicationModal h2 {
	margin-top: 0;
	font-weight: normal;
}

.ImportApplicationModal__form {
	display: grid;
	column-gap: 1em;
	grid-template-columns: 1fr 20px 1fr;
}

.ImportApplicationModal__from,
.ImportApplicationModal__to,
.ImportApplicationModal__confirm {
	background: #fff;
	padding: 1em;
}

.ImportApplicationModal label {
	display: block;
	margin: 1em 0 1em;
}

.ImportApplicationModal label > span {
	font-weight: bold;
}

.ImportApplicationModal input[type="checkbox"] {
	font-size: 16px;
	height: 16px;
	width: 16px;
	margin: 4px;
}

.ImportApplicationModal__arrow {
	content: "→";
	align-self: center;
	text-align: center;
	font-size: 2em;
}

.ImportApplicationModal__to-app {
	font-weight: bold;
	font-size: 1.1em;
}

.ImportApplicationModal .input {
	border-radius: 4px;
	border: 1px solid #ccc;
	font-size: 15px;
	line-height: 34px;
	padding: 0 10px;
}

.ImportApplicationModal__description {
	font-size: 0.9em;
	color: #999;
}

.ImportApplicationModal .path {
	margin: 8px 0 0 0;
	color: #aaa;
}

.ImportApplicationModal form > div {
	display: flex;
	flex-shrink: 0;
	flex-direction: column;
}

.ImportApplicationModal .submit {
	display: flex;
	margin: 0;
	padding: 1em 3em;
	column-gap: 2em;
	justify-content: center;
	grid-column: 1 / 4;
}

.ImportApplicationModal__confirm p {
	line-height: 1.7;
}

.ImportApplicationModal__confirm-assets {
	margin-left: 19px;
}
